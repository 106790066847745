.help-item {
    padding: .5em 2em;
    box-shadow: 0 5px 8px 0 rgba(0,0,0,0.1);
    border: 1px solid #eee;
    align-self: stretch;
    margin: 10px
}

.help-item h4 {
    font-weight: bold;
}

/* Portrait */

@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
}

/* Landscape */

@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2)  {
}

@media only screen and (min-width: 768px) and (orientation: landscape) {
    .help-item {
        flex: 0 0 31.333333%;
    }
}

@media only screen and (min-width: 768px) and (orientation: portrait) {
    .help-item {
        flex: 0 0 30.333333%;
    }
}