@import "../utilities/variables.scss";
@import "../utilities/mixins.scss";

/****** MAP ******/

%map-controls {
    background: $white;
    padding: $base-padding-small;
    color: $black;
    box-shadow: $base-box-shadow;
    display: inline-block;
    border-radius: $br-default;
    cursor: pointer;
    margin: $base-margin;
    transition: box-shadow $base-transition;

    &:hover {
        box-shadow: $base-box-shadow-hover;
    }
}

// Map pins

.map {
    width: 100%;
    height: 100%;

    &-amenity {
        background: $color-primary;
        height: 15px;
        width: 15px;
        border-radius: $br-round;
        box-shadow: $base-box-shadow;
        cursor: pointer;
        position: relative;

        &:hover {
            background: darken($color-primary, 10%);
        }

        .amenity-snapshot {
            position: absolute;
            top: 0;
            left: 100%;
            width: 250px;
            box-shadow: $base-box-shadow;
            background: $white;
            z-index: $z-index-medium;
            padding: $base-padding-small;
            border-radius: $br-default;
        }
    }
}

// Map Controls

.map-control {
    @extend %map-controls;
    // Themes
    &.selected {
        background: $color-primary;

        span, i {
            color: $white;
        }
    }
}