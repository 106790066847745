@import "../utilities/variables.scss";
@import "../utilities/mixins.scss";
@import "../base/base.scss";

/****** CONSUMER ADMIN STYLES ******/

.consumer-admin-checkbox {
    padding: $base-padding-small $base-padding;
    background: $white;
    border: $base-border;
    border-radius: $br-default;
    color: $black;
    margin-bottom: $base-margin;
    margin-right: $base-margin;
    cursor: pointer;
    label {
        border: 0;
        border-radius: 0;
    }

    input[type=checkbox]:checked + label, input[type=radio]:checked + label {
        transition: border $base-transition;
        border-bottom: 3px solid $color-primary;
    }
}