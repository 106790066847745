.longform-tab {
    background-color: #555;
    color: white;
    padding: 16px 20px;
    border: none;
    cursor: pointer;
    position: fixed;
    bottom: 0px;
    right: 28px;
    z-index: 9999;
    width: 130px;
}

.longform-modal {
    display: none;
    position: fixed;
    z-index: 99999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
}

.modal-header {
    padding: 10px 20px;
}

.longform-modal-content {
    overflow: hidden;
    overflow: -moz-hidden-unscrollable;
    position: relative;
    z-index: 1000;
    max-width: 50vw;
    margin: 2.5% auto 0;
    margin: 5vh auto 0;
    background: #fff;
}

.modal-body {
    overflow: hidden;
    overflow: auto;
    overflow-y: visible;
    overflow-y: auto;
    display: block;
    width: 100%;
    max-height: 14em;
    max-height: 70vh;
    height: 90%;
    margin: 0;
    padding: 5px 20px 0 20px;
    border-right: 1px solid #eee;
    border-left: 1px solid #eee;
    color: #212121;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
        width: 3px;
        height: 3px;
    }

    &::-webkit-scrollbar-track {
        background-color: #fff;
    }

    &::-webkit-scrollbar-track-piece {
        background-color: #fff;
    }

    &::-webkit-scrollbar-thumb {
        height: 50px;
        background-color: #999;
        border-radius: 3px;
    }

    &::-webkit-scrollbar-corner {
        background-color: #999;
    }

    &::-webkit-resizer {
        background-color: #666;
    }
}

@media only screen and (max-width:720px) {

    .longform-modal-content {
        margin: 0;
        max-width: 100vw;
        width: 100%;
    }

    .modal-body {
        max-height: 85.5vh;
        height: 100%;
    }
}

.close-modal {
    float: right;
    cursor: pointer;
    padding: 10px 0;
}

.close-modal:after {
    clear: both;
}

.close {
    color: #aaaaaa;
    float: right;
    font-weight: bold;
    cursor: pointer;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

/*.modal-body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}

.modal-body::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5;
}

.modal-body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #6f6565;
}*/
