﻿@import "../utilities/variables.scss";
@import "../utilities/mixins.scss";

/****** BASE ******/

// Fonts


@font-face {
    font-family: Abril;
    src: url(#{$fonts-path}/AbrilFatface-Regular.ttf);
}

/*@font-face {
    font-family: Great Vibes;
    src: url(#{$fonts-path}/GreatVibes-Regular.ttf);
}

@font-face {
    font-family: Pacifico;
    src: url(#{$fonts-path}/Pacifico-Regular.ttf);
}*/

@font-face {
    font-family: Playfair;
    src: url(#{$fonts-path}/PlayfairDisplaySC-Regular.ttf);
}

@font-face {
    font-family: Quicksand;
    src: url(#{$fonts-path}/Quicksand-Regular.ttf);
}

// @font-face {
//     font-family: Montserrat;
//     src: url(fonts/Montserrat-Regular.ttf);
// }

%small-uppercase-text {
    font-size: $type-small;
    font-weight: $font-weight--bold;
    text-transform: uppercase;
    margin-bottom: $base-margin;
    letter-spacing: $letter-space;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

html, body {
    padding: 0;
    margin: 0;
    height: 100%;
}

html {
    box-sizing: border-box;
}

body {
    font-family: $font-family--primary;
    font-size: $type-base;
    color: $dark-grey;
    line-height: $base-line;
    background: $white;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
}

.fixed {
    position:fixed !important;
    z-index: 9999;
}

.float-left {
    float: left;
    text-align: left;
}

.float-right {
    float: right;
    text-align: right;
}

.divider {
    border: 1px solid $base-border-color;
    width: 100%;
    margin: $base-margin-large 0;

    &.small {
        margin: $base-margin 0;
    }
}

.or-divider {
    position: relative;

    .or-text {
        background: white;
        padding: $base-padding-small;
        position: absolute;
        top: -18px;
        left: 50%;
        transform: translateX(-50%);
        font-weight: medium;
    }
}

.signup-separator {
    hr {
        width: 100%;
    }
}

// Body States (for nav on mobile)

.with-sidebar {
    -webkit-transform: translateX(250px);
    transform: translateX(250px);
    position: fixed;
    width: 100%;
    transition: transform $base-transition;

    .site-cache {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: $z-index-large;
        background-color: rgba(0, 0, 0, 0.6);
    }
    @include mq(medium-landscape) {
        position:relative;
        transform: none;
        transition:unset;
        .site-cache {
            display:none;
        }
    }
}

// Text

p {
    font-family: $font-family--primary;
}

.lead {
    font-size: $type-h4;
    font-weight: $font-weight--light;
}

h1, h2, h3, h4, h5 {
    font-family: $font-family--secondary;
    font-weight: $font-weight--bold;
    color: $black;
    margin: $base-margin-medium 0;
}

h1 {
    font-size: $type-h1;
}

h2 {
    font-size: $type-h2;
}

h3 {
    font-size: $type-h3;
}

h4 {
    font-size: $type-h4;
    font-weight: $font-weight--medium;
}

h5 {
    font-size: $type-small;
    @extend %small-uppercase-text;
    font-weight: $font-weight--bold;
}

.title-underline {
    &:after {
        content: '';
        width: 50px;
        max-height: 3px;
        font-size: 2px;
        background: $color-primary;
        padding: 1em 0;
        display: block;
        margin-top: 21px;
    }
}

a {
    color: $color-primary;
    cursor: pointer;
    text-decoration: none;
    transition: color $base-transition;

    &:hover {
        color: darken($color-primary, 20%);
    }

    &.link-blue {
        color: #09C;
        text-decoration: underline;

        &:hover {
            color: darken(#09C, 10%);
        }
    }
    &.link-underline {
        text-decoration: underline;
        font-weight:bold;
    }
}

.text-center, .center {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-grey {
    color: $grey !important;
}

.text-blue {
    color: $color-blue !important;
}

.text-red {
    color: $color-red !important;
}

.text-yellow {
    color: $color-yellow !important;
}

.text-green {
    color: $color-green !important;
}

.text-white {
    color: $white !important;
}

.text-smalluppercase {
    @extend %small-uppercase-text;
}

.text-small {
    font-size: $type-small;
}

.text-default {
    font-size: $type-base;
}

.text-medium {
    font-size: $type-h4;
}

.text-large {
    font-size: $type-h3;
}

.text-xlarge {
    font-size: $type-h2;
}

.text-xxlarge {
    font-size: $type-h1;
}

.text-underline {
    text-decoration: underline;
}

.text-regular {
    font-weight: 400 !important;
}

.text-bold {
    font-weight: bold;
}

.text-italic {
    font-style: italic;
}

.text-accent {
    font-family: $font-family--accent !important;
}

.pacifico {
    font-family: Pacifico;
}

.abril {
    font-family: Abril;
}

.greatvibes {
    font-family: Great Vibes;
}

.playfair {
    font-family: Playfair;
}

.quicksand {
    font-family: Quicksand;
}


// Position Helpers

.sidebar-fixed {
    position: fixed;
    top: 0;

    @include mq(small) {
        position: relative;
        top: auto;
    }
}

// Margin padding helpers

.margin-bottom {
    margin-bottom: $base-padding;
}

.margin-top {
    margin-top: $base-padding !important;
}

.no-margin-bottom {
    margin-bottom: 0;
}

.no-margin-top {
    margin-top:0 !important;
}

.fixed-nav-margin {
    @include mq(small) {
        margin-top: 49px;
    }
}

.no-padding {
    padding: 0 !important;
}

.no-margin {
    margin:0;
}

// Mobile State

@include mq(small) {
    .site-container {
        overflow: hidden;
    }
}

select.dropdown {
    background: white url('//s-static.cinccdn.com/img/caret2.png') no-repeat;
    background-size: 21px;
    background-position: right center;
    padding: 12px 21px 12px 12px;
    //border: 1px solid $base-border-color;
    //margin-bottom: $base-margin;
    border-radius: $br-default;
    @include vendor-prefix(appearance, none);
    line-height: initial;

    &::-ms-expand {
        display: none;
    }
}