@import "../utilities/variables.scss";
@import "../utilities/mixins.scss";

/****** Tabs ******/

.tabs {
    &-links {
        padding: 0;
        margin: 0;

        li {
            display: inline-block;
            padding: $base-padding-small 0;
            color: $black;
            text-align: center;
            transition: color $base-transition;
            margin-bottom: $base-margin;
            margin-right: $base-padding;

            &.active {
                color: $color-primary;
                border-bottom: 2px solid $color-primary;
            }

            &:hover {
                color: $color-primary;
            }
        }
    }

    .tab {
        display: none;

        &.active {
            display: block;
        }
    }
}