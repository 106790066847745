﻿/****** FORMS ******/
@import "../utilities/variables.scss";
@import "../utilities/mixins.scss";

.form-group {
    // States
    &.success {
        label {
            color: $color-green;
        }
    }

    &.error {
        label {
            color: $color-red;
        }
    }

    &.horizontal {
        display: inline-block;
        padding-right: $base-padding-small;
    }
}

.help-block {
    font-size:12px;
    margin-top: -10px;
}