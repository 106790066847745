@import "../utilities/variables.scss";
@import "../utilities/mixins.scss";

/****** Pagination ******/

.pagination {
    text-align:center;
    margin-top: $base-margin-large;
    &-item {
        display: inline-block;
        padding: 0 $base-padding-small;
        color: $color-primary;
        border-radius: $br-default;
        transition: $base-transition;
        &:hover {
            background: $light-grey;
        }

        &.active {
            color: $white;
            font-weight: $font-weight--bold;
            background: $color-primary;
        }
        &.next {
            
        }
    }
}
