/* ==========================================================================
// VARIABLES
 ========================================================================== */

// Font Stacks

$font-family--primary: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-family--secondary: 'Roboto', Helvetica, Arial, sans-serif;
$font-family--accent: 'Work Sans'; // This affects the logos mainly

// Font Weights

$font-weight--thin: 100;
$font-weight--light: 300;
$font-weight--medium: 400;
$font-weight--bold: 700;
$font-weight--ultra-bold: 900;

// UI Colors

$color-blue: #1976D2;
$color-green: #4CAF50;
$color-red: #E53935;
$color-yellow: #FDD835;
$color-orange: #FF9800;
$color-pdq: #09C;
$color-etta: #1fac90;

// Color Usage

$color-primary: $color-blue;
$color-secondary: $color-red;
$black: #1F2930;
$white: #fff;
$grey: lighten($black, 42%);
$light-grey: lighten($black, 78%);
$xlight-grey: lighten($black, 82%);
$dark-grey: lighten($black, 11%);
$color-shadow: rgba($black, .2);
$white-overlay: rgba($white,0.88);

// Color Palette Modifiers

$color-palettes: ( social: ( facebook : #3b5998, google-plus : #d34836, youtube : #bb0000, linkedin : #007bb6, twitter : #00aced, pinterest : #C92228, vimeo : #aad450 ), theme: ( body-bg : $white, // #theme
header-bg : $black, // #theme
brand-bar-bg : $white, // #theme
footer-bg : $black, // #theme
hero-bg : darken($white,5%), // #theme
hero-overlay : darken($color-primary,16%), // #theme
searchbar-bg : $color-primary, // #theme
button-bg : $color-primary, // #theme
heading-basic : $color-primary, // #theme
heading-accent : $color-secondary, // #theme
heading-neutral : $grey, // #theme
heading-reverse : $white, // #theme
text-basic : $black, // #theme
text-reverse : $white, // #theme
text-calltoaction : $white, // #theme
section-white-bg : $white, // #theme
section-light-bg : $light-grey, // #theme
section-dark-bg : $black, // #theme
section-shaded-bg : $light-grey, // #theme
card-bg-color : lighten($white, 10%), // #theme
card-header-color : $grey, // #theme
link-color : $color-primary, // #theme
header-link : $color-primary // #theme
// #theme {PaletteMap_Formatted}
) );


// UI Colors

$ui-colors: ( default : $color-primary, secondary : $color-secondary, success : $color-green, error : $color-red, warning : $color-yellow, info : $color-blue, dark : $black, neutral : $grey, white : $white, facebook : #3b5998, google-plus : #d34836, youtube : #bb0000, linkedin : #007bb6, twitter : #00aced, vimeo : #aad450, pinterest : #C92228, );

// Text

$base-font--size: 16px;
$base-line--small: 1;
$base-line: 1.7;
$letter-space: 1px;

// Type Scale

$type-scale: 1.333;
$type-base: $base-font--size;
$type-h4: $type-base * $type-scale; // 21px
$type-h3: $type-h4 * $type-scale; // 28px
$type-h2: $type-h3 * $type-scale; // 38px
$type-h1: $type-h2 * $type-scale; // 50px
$type-lg: $type-h1 * $type-scale;
$type-xl: $type-lg * $type-scale;
$type-button: 14px;

$type-small: $type-base / $type-scale; // 12px
$type-xs: $type-small / $type-scale; // 9px

// Border radius

$br-default: 3px;
$br-round: 50%;

// Transitions

$trns-duration: .3s;

// Path to Assets

$path--rel: "https://s-static.cinccdn.com/img/consumer";
$fonts-path: "../../../fonts";

// Grid

$g-col-width: 75px !default;
$g-gutter-width: 24px !default;
$g-col-count: 12 !default;
$g-cont-max-w: 1350px !default;

$grid-half: (100% / 2);
$grid-1of6: (100% / 6);
$grid-1of4: (100% / 4);
$grid-1of3: (100% / 3);
$grid-2of3: ( 100% / 3 ) * 2;

// Breakpoints

$brkpoint-sm: 1px;
$brkpoint-md: 870px;
$brkpoint-lg: 1100px;
$brkpoint-xl: 1440px;


// Base module styles

$base-border: 1px solid darken($light-grey, 5%);
$base-border-color: darken($light-grey, 5%);
$base-box-shadow:0 1px 4px rgba(0,0,0,.10);
$base-box-shadow-hover: 2px 4px 10px rgba(0,0,0, .3);
$base-transition: .2s ease-in-out;

// z-index

$z-index: 1;
$z-index-medium: 9;
$z-index-large: 99;
$z-index-xlarge: 999;

// Padding

$padding-scale: 1.333;
$base-padding: 1em;
$base-padding-medium: $base-padding * $padding-scale; // 21px
$base-padding-large: $base-padding-medium * $padding-scale; // 28px
$base-padding-xlarge: $base-padding-large * $padding-scale; //38px
$base-padding-xxlarge: $base-padding-xlarge * $padding-scale; // 50px

$base-padding-small: $base-padding / $padding-scale; // 12px
$base-padding-xsmall: $base-padding-small / $padding-scale; // 9px
$base-padding-xxsmall: $base-padding-xsmall / $padding-scale; // 5px


// Margin

$base-margin: .5em;
$base-margin-medium: 1em;
$base-margin-large: 2em;



