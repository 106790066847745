﻿@import "../utilities/variables.scss";
@import "../utilities/mixins.scss";
@import "../base/base.scss";

/****** IMAGE GALLERY ******/

$image-margin: 5px;

.image-gallery {
    height: 600px;
    position: relative;
    width: 100%;
    overflow: hidden;

    @include mq(small) {
        height: 250px;

        &:after {
            content: '';
            position: absolute;
            top: 180px;
            left: 0;
            right: 0;
            bottom: 0;
            background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.01) 1%, rgba(0,0,0,0.58) 69%, rgba(0,0,0,0.58) 98%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.01) 1%,rgba(0,0,0,0.58) 69%,rgba(0,0,0,0.58) 98%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.01) 1%,rgba(0,0,0,0.58) 69%,rgba(0,0,0,0.58) 98%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#94000000',GradientType=0 ); /* IE6-9 */
        }
    }

    @include mq(small-tablet) {
        height: 250px;
        &:after {
            content: '';
            position: absolute;
            top: 100px;
            left: 0;
            right: 0;
            bottom: 0;
            background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(130,130,130,0) 49%, rgba(0,0,0,0.5) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(130,130,130,0) 49%,rgba(0,0,0,0.5) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(130,130,130,0) 49%,rgba(0,0,0,0.5) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#80000000',GradientType=0 ); /* IE6-9 */
        }
    }
    &-count {
        padding-bottom: $base-padding-medium;
    }

    .gallery-container {
        white-space: nowrap;
        position: relative;
        width: 100%;
    }

    ul {
        li {
            img {
                width: 100%;
                height: 100%;
                margin: auto;
                display: block;
                object-fit: cover;
                cursor: pointer;
            }

            &:last-child {
                margin-right: 0;
            }

            &.feature-img {
                height: 600px;

                .img {
                    width: 100%;
                    height: 600px;
                }

                @include mq(small) {
                    height: 250px;
                    width: 100%;

                    .img {
                        height: 250px;
                    }
                }

                @include mq(small-tablet) {
                    height: 300px;

                    .img {
                        height: 300px;
                    }
                }
            }

            &.small-imgs {
                .img {
                    height: calc(300px - (#{$image-margin} / 2));
                    width: 100%;
                    margin-bottom: $image-margin;

                    &:last-of-type {
                        margin-bottom: 0;
                    }

                    @include mq(small) {
                        height: 250px;
                    }

                    @include mq(small-tablet) {
                        height: 300px;
                    }
                }

                @include mq(small) {
                    width: 100%;
                }
            }
        }
    }
    .gallery-arrow {
        font-size: $type-h2;
        color: $white;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        z-index: 1;

        &.left {
            left: 0;
        }

        &.right {
            right: 0;
        }

        &.faded {
            opacity: .75;
        }
    }

    &-num {
        color: $white;
        text-align: center;
        padding: $base-padding 0;
    }
}

.testimonial {
    max-width: 850px;
    line-height: 2;
    margin: auto;
    padding: $base-padding-large $base-padding;
    font-weight: 100;
    letter-spacing: 1px;
    position: relative;

    .author {
        @extend %small-uppercase-text;
        display: block;
        margin-top: $base-margin-large;
        text-align: right;
    }

    &:before {
        content: '\201C';
        font-size: $type-h1 * 5;
        font-family: $font-family--secondary;
        position: absolute;
        top: -30px;
        margin-left: -50px;
        line-height: 1;
    }

    @include mq(small) {
        font-size: $type-small;

        p {
            font-size: $type-small;
        }

        &:before {
            margin-left: 0;
        }
    }
}

.neighborhood-gallery {
    margin: 0 !important;

    .neighborhood-slide {
        height: 700px;
        padding: $base-padding-large;

        .guide {
            background: $white;
            padding: $base-padding-large;
            line-height: 2;
            font-weight: 100;
            letter-spacing: 1px;
            max-width: 500px;
            font-size: $type-small;

            h4 {
                margin: 0;
            }
        }
    }
}

.slideshow {
    margin: 0 !important;

    .slideshow-slide {
        height: 700px;
        padding: $base-padding-large;
        position: relative;
    }

    .slideshow-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: transparentize($black, .4);
        padding: 100px;
        color: $white;

        &-content {
            max-width: 600px;
            margin: auto;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            right: 0;
        }

        h1, h2, h3, h4 {
            color: $white;
            margin: 0;
        }
    }
}

.showing-slider {
    .tags {
        width: 100%;
        text-align: center;
        margin-bottom: 0;
    }

    .flex-direction-nav {
        a {
            color: $black;

            &:hover {
                color: $black;
            }
        }

        .flex-next {
            right: 0;
            height: 30px;
            margin: 0;

            &:before {
                font-size: $type-h4;
            }
        }

        .flex-prev {
            left: 0;
            height: 30px;
            margin: 0;

            &:before {
                font-size: $type-h4;
            }
        }
    }
}

#slider, .image-gallery {
    .flex-direction-nav {
        top: 50%;
        position: absolute;
        z-index: 1;
        right: 0;
        left: 0;

        a {
            opacity: 1;
        }

        .flex-prev {
            left: 0;
        }

        .flex-next {
            right: 0;
        }
    }
}

.featured-props {
    position:relative;
    padding: $base-padding $base-padding-xxlarge;

    @include mq(small) {
        margin-left: -38px;
        margin-right: -38px;
    }
    .slides {
        display:flex;
        > li {
            display:flex !important;
        }
    }
    .flex-viewport {
        padding: $base-padding;
    }
    .flex-direction-nav {
        top: 48%;
        position: absolute;
        z-index: 1;
        right: 0;
        left: 0; 
        a {
            opacity: 1;
            color: $grey;
            background:none;
            &:hover {
                color:$black;
            }
        }
        .flex-prev {
            left: 0;
        }

        .flex-next {
            right: 0;
        }
    }
}

.fav-props {
    .fav-count {
        border: $base-border;
        padding: $base-padding-xsmall;
        color: $black;
        i {
            color: $grey;
        }
    }
}

// Default carousel with black constant arrows to the side

.flexslider-default {
    position:relative;
    .flex-direction-nav a {
        opacity:1;
        background: none;
        color: $black;
    }
    .flex-direction-nav .flex-prev {
        left: -40px;
    }
    .flex-direction-nav .flex-next {
        right: -40px;
    }
}

//Flexslider Override styles

.slides,
.slides > li,
.flex-control-nav,
.flex-direction-nav {
    margin: 0;
    padding: 0;
    list-style: none;
    vertical-align: middle;
}

/* ====================================================================================================================
 * BASE STYLES
 * ====================================================================================================================*/
.flexslider {
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.flexslider .slides {
    font-size: 0;
}

.flexslider .slides > li {
    display: none;
    -webkit-backface-visibility: hidden;
    float: none !important;
    display: inline-block !important;
    vertical-align: middle;
    font-size: $type-base;
    overflow:hidden;
}

.flexslider .slides img {
    height: calc(100vh - 200px);
    margin: auto;
    display: block;
    -moz-user-select: none;
    @include mq(small) {
        height: auto;
        width: 100%;
        max-height: 70vh;
    }
}

#carousel {
    position:absolute;
    bottom:0;
    right: $base-padding-xlarge;
    left: $base-padding-xlarge;
    @include mq(small-landscape) {
        display: none;
    }
}

.flexslider .carousel-slides li {
    opacity: .5;
    cursor: pointer;
}

.flexslider .carousel-slides .flex-active-slide {
    opacity: 1;
}

.flexslider .carousel-slides img {
    height: 100px;
    width: 100px;
    margin: 0;
    object-fit: cover;
}

.flexslider .slides:after {
    content: "\0020";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
}

.flexslider {
    margin: 0 0 20px;
    position: relative;
    zoom: 1;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: '' 0 1px 4px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: '' 0 1px 4px rgba(0, 0, 0, 0.2);
    -o-box-shadow: '' 0 1px 4px rgba(0, 0, 0, 0.2);
    box-shadow: '' 0 1px 4px rgba(0, 0, 0, 0.2);
}

.flexslider .slides {
    zoom: 1;
}

.flex-viewport {
    max-height: 100vh;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
}

.loading .flex-viewport {
    max-height: 300px;
}

.carousel li {
    margin-right: 5px;
}

.flex-direction-nav {
    *height: 0;
    z-index: 1;
}

.flex-direction-nav a {
    text-decoration: none;
    display: block;
    width: 40px;
    height: 68px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    overflow: hidden;
    opacity: 0;
    cursor: pointer;
    color: $white;
    background: transparentize($black, .2);
    text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.3);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;

    &:hover {
        color: $white;
    }
}

.flex-direction-nav a:before {
    font-family: "icomoon";
    font-size: 40px;
    display: inline-block;
    content: '\e906';
    text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.3);
}

.flex-direction-nav a.flex-next:before {
    content: '\e907';
}

.flex-direction-nav .flex-prev {
    left: -50px;
}

.flex-direction-nav .flex-next {
    right: -50px;
    text-align: right;
}

.flexslider:hover .flex-direction-nav .flex-prev {
    opacity: 0.7;
    left: 10px;
}

.flexslider:hover .flex-direction-nav .flex-prev:hover {
    opacity: 1;
}

.flexslider:hover .flex-direction-nav .flex-next {
    opacity: 0.7;
    right: 10px;
}

.flexslider:hover .flex-direction-nav .flex-next:hover {
    opacity: 1;
}

.flex-control-nav {
    width: 100%;
    position: relative;
    text-align: center;
    padding: 12px 0;
}

.flex-control-nav li {
    margin: 0 6px;
    display: inline-block;
    zoom: 1;
    display: inline;
}

.flex-control-thumbs {
    margin: 5px 0 0;
    position: static;
    overflow: hidden;
}

.flex-control-thumbs li {
    width: 25%;
    float: left;
    margin: 0;
}

.flex-control-thumbs img {
    width: 100%;
    height: auto;
    display: block;
    opacity: .7;
    cursor: pointer;
    -moz-user-select: none;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
}

.flex-control-paging li a {
    width: 11px;
    height: 11px;
    display: inline-block;
    background: #666;
    background: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    text-indent: -9999px;
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    -o-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
}

.flex-control-paging li a.flex-active {
    background: rgba(0, 0, 0, 1);
}




