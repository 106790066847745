@import "../utilities/variables.scss";
@import "../utilities/mixins.scss";

.splash-icon {
    font-size: 75px;
    color: $color-primary;
}

.step-text {
    font-weight: bold;
}

.splash-btn {
    margin-top: 20px !important;
}

.ty-items {
    width:400px;
}

.loan-info {
    margin-top: 20px;
    padding: 20px;
}

@media (max-width: 768px) and (min-width: 1px) {
    .next-steps-title {
        font-size: 30px !important;
    }
}

.inline-box {
    background-color: #F7F7F7;
    border: 1px solid #E4E4E4;
    margin: 25px;
    padding: 20px;
}

.inline-tc {
    font-size: 12px;
}

.reg-step label {
    font-weight: bold;
}

.reg-thankyou .contact-row {
    display: flex;
    flex-wrap: nowrap;
    align-items: center
}

.reg-thankyou .contact-row .icon {
    flex-basis: 15%;
    text-align: left;
}

.reg-thankyou .contact-row .text {
    flex-grow: 1;
    text-align: left;
}


.company-logo {
    .logo-img {
        max-height:100px;
        margin-bottom: $base-padding;
    }
    @include mq(small) {
        text-align:center;
    }
}
.company-info {
    display: flex;
    position: relative;
    flex-flow: row wrap;
    align-items: center;

    .company-avatar {
        .avatar {
            img {
                border-radius: $br-round;
                height: 80px;
                width: 80px;
            }
            @include mq(small) {
                text-align:center;
            }
        }
    }

    .company-details {
        margin-left: $base-padding;
    }
}

@include mq(small) {
    .inline-box {
        margin: 25px 0px;
    }

    .company-info {
        text-align: center;

        .child {
            flex-basis: 100%;
        }

        .company-avatar {
            width: 100%;
        }

        .company-details {
            margin-left: 0px;
        }
    }

    .ty-items {
        width: 100%;
    }
}

