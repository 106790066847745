﻿@import "../base/base.scss";
@import "../utilities/variables.scss";
@import "../utilities/mixins.scss";

// Autocomplete

.autocomplete {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: $white;
    max-height: 250px;
    overflow-y: scroll;
    padding: $base-padding;
    box-shadow: $base-box-shadow;
    z-index: $z-index-medium;

    .item {
        &.title {
            @extend %small-uppercase-text;
            border-top: $base-border;
        }

        padding: $base-padding-xsmall 0;
    }
}

// Semantic UI overrides

.ui.dropdown .menu {
    font-size: $type-base;
}

.ui .delete.icon {
    font-size: 12px;
    margin-left: 9px;

    &:before {
        content: '\e90d';
        font-family: icomoon;
        font-style: normal;
    }
}

.ui.dropdown > .dropdown.icon {
    margin:0;
}

.ui.dropdown > .dropdown.icon:before {
    font-family: icomoon;
    content: '\e905';
}

.ui.label {
    background-color: $light-grey;
    color: $black;
}

