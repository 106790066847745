@import "../utilities/variables.scss";
@import "../utilities/mixins.scss";

/****** SCORECARD ******/

.scorecard {
    .item {
        height: auto;
        overflow: auto;
        border-bottom: 1px solid darken($light-grey, 10%);

        &:last-of-type {
            border-bottom: 0;
        }

        .attribute {
            width: 60%;
            float: left;
            padding: $base-padding-small 0;
        }

        .option {
            width: 20%;
            float: left;
            padding: $base-padding-small;
            text-align: center;

            i {
                color: $grey;
                font-size: $type-h3;
            }

            h5 {
                margin: 0;
                padding: 0;
            }
        }
    }
}