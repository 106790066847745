﻿@import "../base/base.scss";
@import "../utilities/variables.scss";
@import "../utilities/mixins.scss";

/****** Hero ******/

.hero {
    // Base styles
    width: 100%;
    background-size: cover;
    background: $color-primary;
    position: relative;

    .hero-title {
        font-size: $type-lg;
        font-weight: $font-weight--medium;
        font-family: $font-family--secondary;
        color: $white;
        text-shadow: 2px 2px 5px transparentize($black, .7);
        line-height: 1.3;
        @include mq(small) {
            font-size: $type-h2;
        }
        &.hero-title-h1 {
            font-size: $type-h1;
        }
    }

    .hero-subtitle {
        font-size: $type-h4;
        font-weight: $font-weight--light;
        color: $white;
        text-shadow: 2px 2px 5px transparentize($black, .7);

        @include mq(small) {
            font-size: $type-base;
        }
    }

    .hero-companyname {
        font-size: $type-base;
        font-weight: $font-weight--bold;
        text-transform: uppercase;
        color: $white;
        text-shadow: 2px 2px 5px transparentize($black, .7);
        font-family: $font-family--primary;
    }

    .hero-text {
        font-size: $type-h4;
        color: $white;
    }

    .hero-searchbar {
        max-width: 600px;
        margin: $base-margin-large 0;
        position: relative;

        .tabs-links li {
            a {
                color: $white;
            }

            &.active {
                color: $white;
                border-bottom: 2px solid $white;
            }
        }
    }

    h1, h2, h3, h4, h5 {
        color: $white;
    }

    &.primary-block {
        &:after {
            content: '';
            background: transparentize($black, .8);
            width: 550px;
            position:absolute;
            top:0;
            bottom:0;
            left:0;
        }
    }

    .hero-overlay {
        padding: 4em 0;

        // Overlay Themes
        &.dark {
            background: transparentize($black, .8);

            .hero-title, .hero-subtitle, .hero-companyname {
                text-shadow: none;
            }
        }

        &.extra-dark {
            background: transparentize($black, .3);

            .hero-title, .hero-subtitle, .hero-companyname {
                text-shadow: none;
            }
        }

        &.radial-gradient {
          /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,1f2930+100&0.1+0,0.6+100 */
background: -moz-radial-gradient(center, ellipse cover, rgba(0,0,0,0.1) 0%, rgba(31,41,48,0.6) 100%); /* FF3.6-15 */
background: -webkit-radial-gradient(center, ellipse cover, rgba(0,0,0,0.1) 0%,rgba(31,41,48,0.6) 100%); /* Chrome10-25,Safari5.1-6 */
background: radial-gradient(ellipse at center, rgba(0,0,0,0.1) 0%,rgba(31,41,48,0.6) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1a000000', endColorstr='#991f2930',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
        }

        &.light {
            background: rgba(255,255,255,.3);
        }

        &.primary-color {
            background: transparentize($color-primary, .5);
        }
        &.secondary-color {
            background: transparentize($color-secondary, .2);

            .hero-title, .hero-subtitle, .hero-companyname {
                text-shadow: none;
            }

            [class^='btn--'] {
                background: transparentize($white, .2);
                color: $color-secondary;
            }
        }

        &.mortgage-rates {
            .hero-title {
                font-size: $type-h2;
            }
        }

        //Searchbar Bottom

        &.hero-searchbar-bottom {
            padding: 200px 0;

            @include mq(small) {
                padding-top:100px;
                padding-bottom: 200px;
            }

            .hero-searchbar-wrapper {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                padding: 1em;
                width: 100%;
                background:transparentize($black, .2);
                max-width: 100%;
                .hero-searchbar {
                    max-width:100%;
                    margin:0;
                    .container {
                        position:relative;
                    }
                }
            }
        }
        // Searchbar floating on bottom
        &.hero-searchbar-floating-bottom {
            .hero-title {
                text-align:center;
            }
            .hero-subtitle {
                text-align: center;
            }
            .hero-companyname {
                text-align:center;
            }
           .hero-searchbar {
                background: $white;
                padding: $base-padding-medium;
                border-radius: $br-default;
                position: absolute;
                bottom: -33px;
                margin: auto;
                left: 50%;
                transform: translateX(-50%);
                box-shadow: $base-box-shadow;
                .tabs-links li.active  {
                    border-color: $black;
                }
                .tabs-links li a {
                    color: $black;
                }
                @include mq(small) {
                    margin:auto;
                    position: relative;
                    padding: $base-padding-small;
                    transform: none;
                    bottom: -60px;
                    left:0;
                }
            }
        }

        // Desmond Searchbar

        &.hero-desmond {
            .hero-searchbar {
                max-width: 800px;
                .searchbar-grid {
                    > div {
                        margin:0;
                        .input {
                            border-radius: 0;
                            margin:0;
                            height: 40px;
                        }
                        .btn--default {
                            padding: $base-padding-xsmall;
                            margin:0;
                            border-radius: 0;
                            line-height: 1.7;
                            font-size: 14px;
                            height:40px;
                        }
                    }
                }
            }
        }

        // Content in black box 

        .hero-dark-box {
            background: rgba(0,0,0,.6);
            padding: $base-padding-large;
            max-width: 600px;
            .hero-title {
                font-size: $type-h2;
            }
        }

        // Content on bottom 
        &.hero-content-bottom {
            padding-top: 30%;
            padding-bottom: $base-padding-medium;
            .hero-title {
                font-size: $type-h2;
            }
            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */
        }

        // Text Alignment

        &.hero-text-center {
            .hero-title {
                text-align:center;
            }
            .hero-subtitle {
                text-align: center;
            }
            .hero-companyname {
                text-align:center;
            }
            .hero-searchbar {
                margin-left:auto;
                margin-right:auto;
            }
        }

        // Padding themes

        &.padding-xlarge {
            padding: 20em 0;
        }

        &.padding-large {
            padding: 12em 0;

            @include mq(small) {
                padding: 2em 0;
            }
        }

        &.padding-fullscreen {
            padding: 22vh 0;

            &:after {
                content: '\e905';
                color: $white;
                font-family: icomoon;
                font-size: $type-h2;
                bottom: 21px;
                left: calc(50% - 28px);
                position: absolute;
                animation: float 3s ease-in-out infinite;
                background: transparentize($black, .4);
                height: 57px;
                width: 57px;
                text-align: center;
                border-radius: 50%;

                @include mq(small) {
                    display: none;
                }
            }

            @include mq(small) {
                padding: 5em 0;
            }
        }

        &.video {
            position: relative;
            bottom: 0;
            top: 0;
            left: 0;
            right: 0;
            z-index: $z-index-medium;
        }

        &.padding-small {
            padding: 2em 0;
        }
    }
    /* VIDEO BG HERO STYLES */
    .video-container {
        position: absolute;
        bottom: 0%;
        left: 0%;
        height: 100%;
        width: 100%;
        overflow: hidden;

        .hero-overlay {
            position: relative;
            z-index: $z-index-medium;
        }

        #video-background {
            min-width: 100%;
            min-height: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
        }
    }
}

@keyframes float {
    0% {
        transform: translatey(0px);
    }

    50% {
        transform: translatey(-20px);
    }

    100% {
        transform: translatey(0px);
    }
}
