/****** SIDEBAR ******/
@import "../utilities/variables.scss";
@import "../utilities/mixins.scss";

.sidebar {
    background: $light-grey;
    padding: $base-padding-medium;

    .accordion {
        border-bottom: 0;
    }
}
