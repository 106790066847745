@import "../utilities/variables.scss";
@import "../utilities/mixins.scss";

/****** Popover ******/

.popover {
    position: relative;

    label {
        cursor: pointer;
    }

    input[type=checkbox], input[type=radio] {
        display: none;
    }

    input[type=checkbox]:checked ~ .popover-content, input[type=radio]:checked ~ .popover-content {
        opacity: 1;
        z-index: $z-index-large;
        transform: translate(0, -20px);
        transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
        display:block;
    }
    .popover-content {
        position: absolute;
        top: 150%;
        width: 350px;
        opacity: 0;
        z-index: -1;
        overflow: hidden;
        background: $white;
        border-radius: $br-default;
        border: $base-border;
        padding: $base-padding;
        text-align: left;
        box-shadow: $base-box-shadow;
        display:none;
        transform: translate(0, 10px);

        &.mobile-full-width {
            @include mq(small) {
                width: 100%;
                height: 100%;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                position: fixed;
                border-radius: 0;
                padding-top: $base-padding-xlarge;
            }
        }

        &.right {
            right: 0;
        }

        &.left {
            left: 0;
        }
    }
}
