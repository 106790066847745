@import "../utilities/variables.scss";
@import "../utilities/mixins.scss";

/****** LIST GROUPS ******/


.no-bullets {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.list__simple {
    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;

        > li {
            width: 100%;
            border-bottom: $base-border;
            padding: $base-padding-xsmall 0;
            vertical-align: top;

            &:last-of-type {
                border-bottom: 0;
            }
        }
    }
}

.list__1col {
    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
            margin: 0;
            display: inline-block;
            margin-bottom: $base-margin;
            vertical-align: top;
            &:first-child {
                width: 32%;
                font-weight: $font-weight--bold;
            }
            &:last-child {
                width: 66%;
            }
        }
    }
    &.half {
        ul {
            li {
                margin: 0;
                display: inline-block;
                margin-bottom: $base-margin;
                vertical-align: top;
                width: 49%;
            }
        }
    }
}

.list__2col {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-width: 49%;
    -moz-column-width: 49%;
    column-width: 49%;

    @include mq(small) {
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
        -webkit-column-width: 100%;
        -moz-column-width: 100%;
        column-width: 100%;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
            margin: 0;
            display: inline-block;
            width: 49%;
            vertical-align: top;
            
            &:first-child {
                font-weight: $font-weight--bold;
            }
        }
    }
}

.list__3col {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-width: 32%;
    -moz-column-width: 32%;
    column-width: 32%;

    @include mq(small) {
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
        -webkit-column-width: 100%;
        -moz-column-width: 100%;
        column-width: 100%;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
            margin: 0;
            display: inline-block;

            &:last-child {
                font-weight: bold;
            }
        }
    }
}