@import "../utilities/variables.scss";
@import "../utilities/mixins.scss";
@import "../base/base.scss";

/****** Tables ******/

table {
    p {
        margin:0;
    }
    &.simple {
        width: 100%;
        border: $base-border;

        tr {
            th, td {
                padding: $base-padding-small;
                text-align: left;
            }

            th {
                @extend %small-uppercase-text;
                color: $black;
            }
        }
    }
    &.bordered {
        width: 100%;
        border: $base-border;
        border-bottom:0;
        tr {
            th, td {
                padding: $base-padding-small;
                border-bottom: $base-border;
                word-break: initial;
                &:last-child {
                    border-right:0;
                }
            }

            th {
                @extend %small-uppercase-text;
                color: $black;
                text-align: left;
            }
        }
        @include mq(small) {
            border: 0;
            thead {
                border: none;
                clip: rect(0 0 0 0);
                height: 1px;
                margin: -1px;
                overflow: hidden;
                padding: 0;
                position: absolute;
                width: 1px;
              }
              tr, td {
                  display:block;
              }
              tr {
                  margin-bottom: $base-margin-large;
                  border: $base-border;
              }
              td {
                text-align: right;
              }
              td:before {
                content: attr(data-label);
                float: left;
                font-weight: bold;
              }
        }
    }
}
