@import "../utilities/variables.scss";
@import "../utilities/mixins.scss";
@import "../modules/buttons.scss";

/****** SPLIT SEARCH ******/

.split-search {
    // Make padding for sections smaller
    section {
        padding: $base-padding;
    }

    @include flexbox();

    @include mq(small) {
        display: block;
        height: auto !important;
    }

    // Search listings column
    .search-listings {
        background: $light-grey;
        position: relative;
        width: $grid-half;
        transition: all .5s ease-in-out;

        @include mq(small) {
            width: 100%;
        }

        @include mq(small-md) {
            width: $grid-2of3;
        }

        #searchBar {
            position: relative;
            z-index: 99;
            .input-group.btn {
                width: 100%;
                input {
                    margin-bottom:0;
                    border-radius: $br-default;
                }
            }
            @include mq(small) {
                padding: $base-padding-small;
            }
        }

        #searchProperties {
            &.grid-collapse {
                > div {
                    max-width: 100%;
                    @include flex(0 100%);
                    margin: 0;
                }
            }

            @include mq(small) {
                margin-right: -$base-padding;
                margin-left: -$base-padding;
            }
        }

        .filter-panel {
            display: none;

            &.open {
                display: block;
                border-top: 1px solid $light-grey;
                padding-top: $base-padding;
                padding-bottom: 100px;
                background: $white;
            }
        }
        .listing-loading-skeleton {
            display:none;
        }
        #homeCountLabel {
            margin-bottom:0;
        }
    }
    .filters-toolbar {
        display:none;
        width: $grid-half;
        background: $light-grey;
        box-shadow: $base-box-shadow;
        padding: $base-padding;
        position: fixed;
        bottom: 0;
        left: 0;
        text-align: right;
        z-index: $z-index-medium;

        @include mq(small) {
            width: 100%;
        }

        @include mq(small-md) {
            width: $grid-2of3;
        }
    }
    .filter-tag-wrap {
        margin-top: $base-padding-small;
        .popover {
            display:inline-block;
        }
    }
    // Map column
    .search-map {
        position: fixed;
        overflow: none;
        width: $grid-half;
        right:0;
        transition: all .5s ease-in-out;
        #map-search-results {
            height: 100vh;
        }
        @include mq(small-md) {
            width: $grid-1of3;
        }
        @include mq(small) {
            max-height: 77vh;
            position:relative;
            width: 100%;
            #map-search-results {
                display: none;
            }
        }
    }


    // Misc
    .search-filters-list {
        font-size: $type-small;
        overflow: hidden;
    }

    #searchListingHeader {
        margin-bottom: $base-padding;
    }

    .sortby {
        text-align: right;
        @include mq(small) {
            text-align: left;
        }
    }
}

.search-page {
    header {
        position:fixed;
        @include mq(small) {
            position:relative;
        }
    }
    .site-container {
        padding-top: 104px;
        @include mq(small) {
            padding-top:0;
        }
    }
}

// Camelot Theme Overrides

.camelot-search-page {
    .popover {
        input[type=checkbox]:checked ~ label .tags {
            background: $color-primary;
            color: $white;
        }
    }
    .button-selected {
        background-color: $color-primary;
        color: $white;
    }
    .filter-tag-wrap {
        .tags {
            margin-right: 2px;
            box-shadow: $base-box-shadow;
            border:0;
        }
    }
    .location-tags-wrap {
        box-shadow: $base-box-shadow;
        border:0;
    }
    // Mobile Web Styles

    @include mq(small) {
        .filter-panel.open {
            padding-top: 160px !important;
        }
        .search-map {
            max-height: 100vh;
        }
        .no-listing-results {
            padding: $base-padding;
            margin-top:50px;
        }
        header {
            position:fixed;
            min-height:auto;
            &.bordertop {
                border:0;
            }
            .header-icon {
                left: 12px;
                top:12px;
            }
			.icon-more {
                left: 30px;
                top: 15px;
            }
            .header-items .logo {
                display:none;
            }
        }
        footer {
            display:none;
        }
        .popover {
            position:unset;
            .popover-content {
                left:0;
                padding: $base-padding-large $base-padding;
                top: 100%;
                width: 95%;
                margin: $base-margin;
            }
        }
        .filter-tag-wrap {
            .tags {
                font-size:14px;
             }
        }
        .search-listings {
            #searchBar {
                width: 100%;
                background: $white;
                box-shadow: none;
                border-radius: $br-default;
                padding: $base-padding;
                .searchbar-wrap {
                    background: $white;
                    padding: 0 $base-padding;
                    padding-left: $base-padding-large;
                    border-radius: $br-default;
                    box-shadow: $base-box-shadow;
                }
                .location-tags-wrap {
                    box-shadow: none;
                    border:0;
                }
                &.open {
                    position:absolute;
                }
                &.searchbar-map-view {
                    position:fixed;
                    background: transparent;
                }
            }
        }
    }
    &.expanded {
        .saved-search-element span {
            display:none;
        }
    }
}

// Desmond Theme overrides 

.desmond-search-page {
    @include mq(small) {
        header {
            position:fixed;
            .header-icon.has-social {
                top: 55px;
            }
			.icon-more.has-social {
                top: 55px;
            }
        }
        .search-listings #searchBar {
            margin-top:41px;
        }
    }
}

// Add fixed states on scroll
.searchbar-fixed {
    position: fixed !important;
    z-index: $z-index-large;
    width: $grid-half;
    @include mq(small-md) {
        width: $grid-2of3;
    }
    @include mq(small) {
        top:0;
        width: 100%;
    }
}

// Fixed state for quick details on scroll down
.quickdetails-fixed {
    position:fixed !important;
    z-index: 4;
    width: $grid-half;
    margin-top: 0px !important;
    @include mq(small-md) {
        width: $grid-2of3;
    }
    .card--property2-expanded {
        box-shadow: $base-box-shadow;
    }
}

// Quick details wrapper
#search-map-selected-prop, #search-map-selected-prop-mobile {
    margin-top: -$base-padding;
    margin-left: -$base-padding;
    margin-right: -$base-padding;
    margin-bottom: $base-padding;
    transition: width .5s ease-in-out;
    @include mq(small) {
        margin: 0;
        position:absolute;
        bottom:0;
        left:0;
        right:0;
    }
    @include mq(small-landscape) {
        margin: 0;
        position:absolute;
        top:0;
        left:0;
        right:$grid-half; 
    }
}

// Search input
#search-search-bar {
    position:relative;
}


// Whenever the map is expanded, this class will attach to <body>
// in order to target the footer as well.
.expanded {
    .split-search {
        .search-listings {
            width: $grid-1of3;
            .card--property {
                margin-bottom: $base-margin;
            }
    
            @include mq(small-md) {
                width: $grid-half;
            }
            @include mq(small) {
                width: 100%;
            }
        }
        .filters-toolbar {
            width: $grid-1of3;
            @include mq(small-md) {
                width: $grid-half;
            }
        }
        .search-map {
            width: $grid-2of3;
            @include mq(small) {
                display:none;
            }
            @include mq(small-md) {
                width: $grid-half;
            }
        }
        .card--property2-expanded {
            border: 3px solid lighten($color-primary, 25%);
            > .grid__flex {
                display:block;
                > div {
                    max-width: 100%;
                }
            }
            .close {
                top: 265px;
            }
        }
        
    }
    .search-footer {
        width: $grid-1of3;
        .grid__flex--gutter {
            display:block;
            text-align:center;
            > div {
                min-width: 100%;
            }
        }
        @include mq(small-md) {
            width: $grid-half;
        }
    }
    .searchbar-fixed, .quickdetails-fixed {
        width: $grid-1of3;
        @include mq(small-md) {
            width: $grid-half;
        }
    }
}


// Pertains to footer, header, container on search page only

.search-footer {
    width: $grid-half;
    .grid__flex--gutter {
        display:block;
        text-align:center;
        > div {
            min-width: 100%;
        }
    }
    @include mq(small) {
        width: 100%;
    }
    @include mq(small-md) {
        width: $grid-2of3;
    }
}

// Animations

.slideLeft {
    animation: 1s ease-out 0s 1 slideInFromLeft;
}

.slideTop {
    animation: 1s ease-out 0s 1 slideInFromTop;
}

@keyframes slideInFromLeft {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes slideInFromTop {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0);
    }
  }

