﻿@import "../utilities/variables.scss";
@import "../utilities/mixins.scss";

/****** Accordions ******/

.accordion {
    display: block;
    border-top: $base-border;
    border-bottom: $base-border;
    padding: $base-padding 0;

    &.accordion-details {
        padding: $base-padding-large 0;
        border-top: 0;
    }

    .accordion-content {
        max-height: 0;
        overflow: hidden;
        transition: max-height .35s;

        .inner {
            padding: $base-padding-medium 0;
        }
    }

    input[type=checkbox] {
        display: none;
    }

    input[type=checkbox]:checked + label.accordion-header {
        &:after {
            transform: rotateX(180deg);
        }
    }

    input[type=checkbox]:checked ~ .accordion-content {
        max-height: 100%;
        overflow: initial;
    }

    label.accordion-header {
        display: block;
        width: 100%;
        font-size: $type-h4;
        color: $black;
        font-family: $font-family--secondary;
        font-weight: $font-weight--bold;
        cursor: pointer;
        position: relative;

        &:after {
            content: '\e905';
            font-family: icomoon;
            font-size: $type-h4;
            position: absolute;
            top: 2px;
            right: 0;
            transition: all .35s;
        }
    }
    &.accordion-mls {
        label.accordion-header {
            &:after {
                content: '';
            }
        }
        .accordion-content {
            max-height: 100%;
            overflow:initial;
        }
    }
}