﻿@import "../utilities/variables.scss";
@import "../utilities/mixins.scss";
@import "../base/base.scss";

/****** Cards ******/

.card {
    // Base styles
    background: $white;
    box-shadow: $base-box-shadow;
    border-radius: $br-default;
    border: $base-border;
    margin-bottom: $base-margin-medium;

    .card-header {
        padding: $base-padding;
        font-size: $type-base;
        font-weight: $font-weight--bold;
        border-bottom: $base-border;

        span {
            display: block;
            font-size: $type-small;
            font-weight: $font-weight--medium;
            color: $grey;
        }
    }

    .card-body, .card-wrap {
        padding: $base-padding;
        font-size: $type-base;
    }

    .card-footer {
        padding: $base-padding;
        font-size: $type-base;
        border-top: $base-border;
        color: $grey;
    }

    .card-image {
        height: 300px;
        background: $grey;
        background-size: cover;
        border-top-left-radius: $br-default;
        border-top-right-radius: $br-default;

        &.small-image {
            height: 200px;
        }
    }

    // Colors 

    &.card--info {
        background: lighten($color-blue, 50%);
        border-color: $color-blue;
    }
    // Themes
    &.simple {
        .card-header {
            border-bottom: 0;
            padding-bottom: 0;
        }

        .card-footer {
            padding-top: 0;
            border-top: 0;
        }
    }

    &.testimonials {
        padding: $base-padding-medium;
    }

    &.date {
        @include flexbox();

        .card-date {
            background: $color-primary;
            padding: 16px;
            color: $white;
            font-size: $type-h1;
            @include flex(0 25%);
            text-align: center;
            line-height: 1.3;
            border-top-left-radius: $br-default;
            border-bottom-left-radius: $br-default;

            span {
                font-size: $type-small;
                letter-spacing: $letter-space;
                display: block;
                text-transform: uppercase;
            }
        }

        .card-body {
            @include flex(1);

            b {
                @extend %small-uppercase-text;
                color: $color-blue;
            }
        }
    }

    &.info {
        background: transparentize($color-blue, .9);
        border: 1px solid $color-blue;
    }

    &.school {
        .school-rating {
            text-align: center;
            margin-bottom: $base-margin;
            
        }
        .school-key-info {
            border-bottom: $base-border;
            text-align:center;
            padding: $base-padding;
            .icon-group {
                margin: 0 5px;
                max-width: 26%;
                vertical-align: top;
            }
        }
    }

    &.profile {
        @include flexbox();

        .card-profile {
            @include flex(0);
            padding: $base-padding-xsmall;
        }
    }

    &.home-val-sold-prop {
        position:relative;
        .sold-prop-photo {
            height: 250px;
            width: 100%;
            background: $grey;
            position:relative;
            .badge--property-status {
                position:absolute;
                top: $base-padding;
                left: $base-padding;
            }
            @include mq(small) {
                height: 200px;
            }
        }
        .sold-prop-link-overlay {
            position:absolute;
            top:0;
            left:0;
            right:0;
            bottom:0;
            cursor:pointer;
            z-index:1;
        }
    }
}

.card--property2 {
    // Base styles
    position: relative;
    color: $white;
    width: 100%;
    border-radius: $br-default;
    background: $white;
    box-shadow: $base-box-shadow;
    overflow:hidden;

    .property {
        &-content {
            position:relative;
        }
        &-image-wrapper {
            height: 200px;
            position: relative;
            background: $grey;
            overflow:hidden;
            margin: $base-padding-xsmall;
            @include mq(small) {
                top: $base-padding-small;
            }
            img {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                min-height: 200px;
                width: 100%;
                min-width: 400px;
            }
        }
        &-status {
            font-size: $type-small;
            text-transform: uppercase;
            color: $color-green;
            font-weight: bold;
            white-space: nowrap;
        }
        &-interactions {
            z-index: 3;
        }
        &-info {
            position: relative;
            padding: $base-padding-small;
            padding-top: 0;
            width: 100%;
            color: $black;
            background: $white;
        }
        &-price {
            font-size: $type-h4;
            display: block;
            line-height: $base-line;
            color: $color-primary;
        }

        &-address {
            font-size: $type-small;
            vertical-align: bottom;
        }

        &-bdba {
            text-align: right;
            font-size: 14px;
            color: $dark-grey;
        }

        &-mls {
            font-size: $type-xs;
            color: $black;
            text-align: left;
            display: inline-block;
            width: 100%;

            img {
                float: left;
                padding-right: $base-padding-xsmall;
                max-height: 16px;
                max-width: 50px;
                height: 100%;
            }
            &.mls-top {
                padding: $base-padding-small $base-padding;
                font-size: $type-small;
                background: white;
                opacity: 1;
            }
        }
    }

    .link-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
    }

    // Themes

    &.mls-no-overlay {
        .property {
            &-info {
                position: relative;
                background: $white;
                color: $black;
                top: 0;
            }

            &-image-wrapper {
                &:after {
                    background: none;
                }
            }

            &-interactions {
                width: 100%;
                position: relative;

                .badge--property-status {
                    margin-top: 7px;
                }
            }
        }

        // If card--property also has mls-above-everything, make sure not to add a top value
        &.mls-above-everything {
            .property-interactions {
                top: 0;
            }
        }
        &.mls-color-font-same-style {
            .property-address, .property-bdba, .property-price, .property-mls {
                color: $black !important;
                font-size: $type-base;
                font-weight: initial;
                opacity: 1;
            }
        }
    }

    // If card--property has the mls info above the photo
    &.mls-above-everything {
        .property-interactions {
            top: $base-padding-large;
        }
    }

    // Property images aspect ration for MLS

    &.mls-photo-aspect-ratio {
        .property-image-wrapper {
            background: desaturate(darken($light-grey, 10%), 10%);
            img {
                min-width: auto;
                height: 250px;
                object-fit: contain;
            }

            // IE Fallback

            &.compact-object-fit {
                background-size: contain;
                background-position: center center;
                background-repeat: no-repeat;

                img {
                    opacity:0;
                }
            }
        }
    }

    // Featured property border

    &.featured {
        background: #64c9ee; /* Old browsers */
        background: -moz-linear-gradient(top, #64c9ee 0%, #3498d8 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, #64c9ee 0%,#3498d8 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, #64c9ee 0%,#3498d8 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#64c9ee', endColorstr='#3498d8',GradientType=0 ); /* IE6-9 */
        padding: 4px;
        padding-top: 40px;
        &:before {
            content: '\e926';
            font-family: icomoon;
            position: absolute;
            top: 6px;
            left: 12px;
        }
        &:after {
            content: 'Featured Property';
            color: $white;
            padding: 5px 16px;
            position:absolute;
            top:0;
            left:21px;
            right:0;
            width: 100%;
        }
        .property-mls {
            opacity: 1;
        }
        .property-image-wrapper {
            height: 207px;
            margin:0;
        }
        &.mls-no-overlay {
            .property-content {
                background: $white;
            }
        }
    }

    // Quick details

    &-expanded {
        position:relative;
        box-shadow: $base-box-shadow;
        .card--property2 {
            box-shadow: none;
        }
        .expanded-content {
            background: white;
            left: 100%;
            z-index: $z-index-medium;
            padding: $base-padding;
            padding-right: $base-padding-xlarge;
            color: $black;
            .expanded-footer {
                margin-top: $base-margin;
            }
            .close {
                font-size: $type-base;
                top: $base-margin;
                right: $base-margin;
            }
            @include mq(small) {
                display:none;
            }
        }
        .quick-details-school {
            margin-right: $base-margin;
        }
    }

    // Card loader empty state 

    &:empty:after {
        height: 250px;
        content: '';
        width: 100%;
        display:block;
        background-repeat: no-repeat;
        background-image: 
            linear-gradient(
                90deg, 
                rgba(darken($light-grey, 10%), 0) 0, 
                rgba(darken($light-grey, 10%), .8) 50%, 
                rgba(darken($light-grey, 10%), 0) 100%
            ),
            radial-gradient(circle 22px,#fff 100%,transparent 0),
            linear-gradient(white 20px, transparent 0),
            linear-gradient(white 20px, transparent 0),
            linear-gradient(darken($light-grey, 10%) 100%, transparent 0);

        background-size:
            200px 250px,
            53px 53px,  /* avatar */
            120px 40px, /* title */
            200px 40px, /* title */
            100% 100%;  /* card bg */

        background-position:
            -150% 0,
            97% 16px,  /* avatar */
            24px 170px, /* title */
            24px 200px, /* title */
            0 0;        /* card bg */
        animation: loading 1s infinite;
    }
}

@keyframes loading {
    to {
      background-position:
        350% 0,        
        97% 16px,  /* avatar */
        24px 170px, /* title */
        24px 200px, /* title */
        0 0;
    }
  }



.card--list {
    @include flexbox();
    @extend .card;
    position: relative;
    height: auto;

    > div:first-child {
        @include flex(0 33%);
        padding: $base-padding;
    }

    > div:last-child {
        @include flex(1);
        padding: $base-padding;
    }

    .property {
        &-price {
            font-size: $type-h4;
        }

        &-mls {
            font-size: $type-xs;
            width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            opacity: .75;

            img {
                float: left;
                padding-right: $base-padding-xsmall;
                max-height: 50px;
                max-width: 50px;
                height: 100%;
            }

            &-address {
                color: $color-pdq;
                font-weight: bold;
            }

            &-desc {
                display: -webkit-box;
                -webkit-box-orient: vertical;

                @include mq(small) {
                    display: none;
                }
            }
        }
    }

    .badge--property-status {
        position: absolute;
        top: $base-padding;
        left: $base-padding;
    }

    .btn--fav {
        position: absolute;
        top: $base-padding;
        right: $base-padding;
        z-index: $z-index-medium;
    }

    @include mq(small) {
        display: block;

        > div:first-child {
            padding: $base-padding;
            height: 250px;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(130,130,130,0) 49%, rgba(0,0,0,0.5) 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(130,130,130,0) 49%,rgba(0,0,0,0.5) 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(130,130,130,0) 49%,rgba(0,0,0,0.5) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#80000000',GradientType=0 ); /* IE6-9 */
            }
        }

        > div:last-child {
            padding: 0;
        }

        .property {
            &-info {
                position: absolute;
                top: 120px;
                color: $white;
                padding: $base-padding;
            }

            &-desc {
                display: none;
            }

            &-address {
                font-size: $type-small;
            }

            &-bdba {
                font-size: $type-small;
                margin-bottom: $base-margin;
            }
        }
    }

    &.mls-no-overlay {
        .property {
            &-info {
                position: relative;
                background: $white;
                color: $black;
                top: 0;
            }
        }

        .badge--property-status {
            position: relative;
            top: 0;
            left: 0;
            font-size: $type-small;
        }

        @include mq(small) {
            .btn--fav {
                position: relative;
                float: right;
            }

            > div:first-child {
                height: 200px;

                &:before {
                    background: none;
                }
            }
        }
    }
}

/* NEIGHBORHOOD CARDS 2 */

.neighborhood2 {
    position: relative;
    overflow: hidden;
    min-height: 400px;

    &-content {
        color: $white;
        height: 100%;
        width: 100%;
        padding: $base-padding-large * 2;
        position: relative;

        > div {
            top: 70%;
            transform: translateY(-70%);
            position: absolute;
        }

        h4 {
            color: $white;
            margin: 0;
            text-transform: uppercase;
        }

        p {
            color: $white;
            font-weight: 100;
            max-width: 500px;
        }
    }

    &:nth-child(3n+1) {
        .neighborhood2-content {
            background: transparentize($black, .3);
        }
    }

    &:nth-child(3n+2) {
        .neighborhood2-content {
            background: transparentize($color-primary, .4);
        }
    }

    &:nth-child(3n+0) {
        .neighborhood2-content {
            background: transparentize($color-secondary, .2);
        }
    }

    &:nth-child(1) {
        height: 500px;
    }

    &:nth-child(2) {
        height: 300px;
    }

    &:nth-child(4) {
        margin-top: -200px;
    }
}

/* NEIGHBORHOOD CARDS 1 */

/* Common style */
.neighborhood figure {
    position: relative;
    float: left;
    overflow: hidden;
    margin: 0;
    width: 100%;
    height: 300px;
    background: #3085a3;
    text-align: center;
    cursor: pointer;
}

.neighborhood figure img {
    position: relative;
    display: block;
    min-height: 100%;
    max-width: 100%;
    opacity: 0.8;
}

.neighborhood figure figcaption {
    padding: 2em;
    color: #fff;
    text-transform: uppercase;
    font-size: 1.25em;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.neighborhood figure figcaption::before,
.neighborhood figure figcaption::after {
    pointer-events: none;
}

.neighborhood figure figcaption,
.neighborhood figure figcaption > a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* Anchor will cover the whole item by default */
/* For some effects it will show as a button */
.neighborhood figure figcaption > a {
    z-index: 1000;
    text-indent: 200%;
    white-space: nowrap;
    font-size: 0;
    opacity: 0;
}

.neighborhood figure h2 {
    word-spacing: -0.15em;
    font-size: $type-h4;
    color: $white;
}

.neighborhood figure h2,
.neighborhood figure p {
    margin: 0;
}

.neighborhood figure p {
    letter-spacing: 1px;
    font-size: 68.5%;
}

/*---------------*/
/***** Bubba *****/
/*---------------*/

figure.effect-bubba {
    background: $color-primary;
}

figure.effect-bubba > div {
    width: 100%;
    height: 100%;
    opacity: 0.7;
    -webkit-transition: opacity 0.35s;
    transition: opacity 0.35s;
}

figure.effect-bubba:hover > div {
    opacity: 0.1;
}

figure.effect-bubba figcaption::before,
figure.effect-bubba figcaption::after {
    position: absolute;
    top: 30px;
    right: 30px;
    bottom: 30px;
    left: 30px;
    content: '';
    opacity: 0;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
}

figure.effect-bubba figcaption::before {
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    -webkit-transform: scale(0,1);
    transform: scale(0,1);
}

figure.effect-bubba figcaption::after {
    border-right: 1px solid #fff;
    border-left: 1px solid #fff;
    -webkit-transform: scale(1,0);
    transform: scale(1,0);
}

figure.effect-bubba h2 {
    padding-top: 10%;
    -webkit-transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
    -webkit-transform: translate3d(0,50px,0);
    transform: translate3d(0,50px,0);
}

figure.effect-bubba p {
    padding: 20px 1em;
    opacity: 0;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: translate3d(0,20px,0);
    transform: translate3d(0,20px,0);
    height: 112px;
    overflow: hidden;
    text-overflow: ellipsis;
}

figure.effect-bubba:hover figcaption::before,
figure.effect-bubba:hover figcaption::after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

figure.effect-bubba:hover h2,
figure.effect-bubba:hover p {
    opacity: 1;
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
}

/*---------------*/
/***** Sarah *****/
/*---------------*/

figure.effect-sarah {
    background: $color-primary;
}

figure.effect-sarah > div {
    max-width: none;
    width: 105%;
    height: 100%;
    width: -webkit-calc(100% + 20px);
    width: calc(100% + 20px);
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: translate3d(-10px,0,0);
    transform: translate3d(-10px,0,0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

figure.effect-sarah:hover > div {
    opacity: 0.4;
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
}

figure.effect-sarah figcaption {
    text-align: left;
}

figure.effect-sarah h2 {
    position: relative;
    overflow: hidden;
    padding: 0.5em 0;
}

figure.effect-sarah h2::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background: #fff;
    content: '';
    -webkit-transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
    -webkit-transform: translate3d(-100%,0,0);
    transform: translate3d(-100%,0,0);
}

figure.effect-sarah:hover h2::after {
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
}

figure.effect-sarah p {
    padding: 1em 0;
    opacity: 0;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: translate3d(100%,0,0);
    transform: translate3d(100%,0,0);
}

figure.effect-sarah:hover p {
    opacity: 1;
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
}