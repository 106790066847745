@import "../utilities/variables.scss";
@import "../utilities/mixins.scss";
@import "../base/base.scss";

/****** INPUTS ******/

%input {
    padding: $base-padding-xsmall;
    font-family: inherit;
    font-size: $type-base;
    color: $black;
    background: $white;
    border-radius: $br-default;
    border: $base-border;
    box-sizing: border-box;
    -webkit-appearance: none;
    margin-bottom: $base-margin;
    transition: border $base-transition;
}

.input, .form-control {
    // Base styles
    @extend %input;

    &:focus {
        outline: none;
        border-color: $color-primary;
    }
    // States
    &.disabled {
        background: $light-grey;
    }

    &.error {
        border-color: $color-red;
        margin-bottom: 0;

        &:focus {
            border-color: darken($color-red, 10%);
        }
    }

    &.success {
        border-color: $color-green;
    }
    // Themes
    &.large {
        padding: $base-padding-small;
        font-size: $type-h4;
    }

    &.small {
        font-size: $type-small;
    }
    &.home-value {
        padding:0;
        width:60%;
        margin:0;
        @include mq(small) {
            width: 100%;
        }
    }
    &.search-icon {
        padding-left: 38px;
        background: $white url('#{$path--rel}/search-icon.png') left no-repeat;
        background-size: 28px;
    }

    &.full-width {
        width: 100%;
    }
    // Select
    &.select {
        padding-right: $base-padding-large;
        background: $white url('#{$path--rel}/select-arrow.png') right no-repeat;
        background-size: 28px;
    }
}

// Input groups

.home-value-searchbar {
    width: 60%;
    @include mq(small) {
        width: 100%;
        display:block;
    }
    .input {
        padding: 0;
    }
    [class^=btn--] {
        font-size: $type-button;
        margin-bottom:0;
    }
}
.input-group {
    position:relative;
    &.btn {
        display: table;
        position: relative;

        > a {
            height: 50px;
        }

        > span {
            display: table-cell;
            vertical-align: top;
            width: 1%;

            &:not(:first-child) {
                a {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                    font-size: 14px;
                    line-height: 1.6;
                }
            }
        }

        > input {
            position: relative;
            display: table-cell;
            vertical-align: top;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            float: left;
            height: 50px;

            &:not(:first-child) {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                margin-left: -1px;
            }
        }

        .ui.dropdown input {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    &.to {
        @include flexbox();
        align-items: center;

        div {
            @include flex(1);
        }

        div:nth-child(2) {
            @include flex(0 10%);
            text-align: center;
        }
    }

    &.dollar {
        position:relative;
        .dollars {
            left: 12px;
            position: absolute;
            color: $grey;
            line-height: 38px;
        }

        .input {
            padding-left: 28px;
        }
    }

    &.percent {
        position:relative;
        .percent {
            right: 12px;
            position: absolute;
            color: $grey;
            line-height: 38px;
        }

        .input {
            padding-right: 28px;
        }
    }

    &.label-animation {
        label {
            position:absolute;
            top:16px;
            left:10px;
            -webkit-transition: 300ms ease all;
            transition: 300ms ease all;
            pointer-events: none;
        }
        .input {
            padding-top: 25px;
        }
        input:focus ~ label, input:valid ~ label, textarea:focus ~ label, textarea:valid ~ label {
            position:absolute;
            top: 5px;
            font-size: $type-small;
            color: $grey;
        }
    }
}

.input-label {
    @extend %small-uppercase-text;
    color: $grey;
    display: block;
}

.validation-message {
    margin-bottom: $base-margin;
}


// Checkbox

.checkbox-hidden {
    display: none;
}

.radio, .checkbox {
    display: table;
    margin-bottom: $base-margin;
    input, label {
        display:table-cell;
    }
    input {
        margin-right: $base-margin;
    }

    &.block {
        display: block;
    }
}

// Input Tags
.location-tags-wrap {
    @extend .input;
    padding-left: $base-padding-xlarge;
    background: $white url('#{$path--rel}/search-icon.png') left no-repeat;
    background-size: 28px;
    margin-bottom:0;
    cursor: text;
    #location-tags {
        display: inline-block;
        margin: 0;
        padding: 0;
        vertical-align: middle;
        .search-tag-close {
            display:inline-block;
            position:relative;
            background: $light-grey;
            padding: 3px $base-padding-xsmall;
            padding-right: $base-padding-large;
            border-radius: $br-default;
            color: $black;
            margin-bottom: 3px;
            margin-right: 3px;
            &:after {
                font-family: 'Icomoon';
                content: '\e90d';
                font-size: $type-small;
                position: absolute;
                right: 6px;
                top: 7px;
                cursor: pointer;
            }
        }
        .search-tag {
            display:inline-block;
            position:relative;
            background: $light-grey;
            padding: 3px $base-padding-xsmall;
            border-radius: $br-default;
            color: $black;
            margin-bottom: 3px;
            margin-right: 3px;
        }
        #search-tag-more {
            display: inline-block;
            font-weight: bold;
            margin-right: 9px;
        }
        .tag-more {
            display:inline-block;
            font-weight: bold;
            padding-left: $base-padding-small;
        }
    }
    // Class when there are many tags in input
    &.collapsed {
        input {
            display:none;
        }
    }
    input {
        font-size: $type-base;
        border:0;
        width: auto;
        max-width: inherit;
        display:inline-block;
        &:focus {
            outline:none;
        }
        @include mq(small) {
            width: 90%;
        }
    }
}


// No-UI Slider Overrides

 .noUi-target {
    background: $light-grey;
    border-radius: $br-default;
}

.noUi-connect {
    background: $color-primary;
    border-radius: 4px;
    box-shadow: inset 0 0 3px rgba(0,0,0, 0.45);
    -webkit-transition: background 450ms;
    transition: background 450ms;
}

.noUi-handle {
    border: $base-border;
    border-radius: $br-round;
    background: #FFF;
    cursor: default;
    box-shadow: $base-box-shadow;
}

.noUi-horizontal {
    height: 9px;
    margin: $base-padding 0;
}

.noUi-horizontal .noUi-handle {
    width: 28px;
    height: 28px;
    left: -17px;
    top: -10px;
}

