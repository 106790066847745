@import "../utilities/variables.scss";
@import "../utilities/mixins.scss";

/****** MEDIA ******/

.thumbnail {
    &-round {
        border-radius: $br-round;
        background: $grey;
        margin: $base-margin;
        display: inline-block;

        &.xsmall {
            height: 25px;
            width: 25px;
        }

        &.small {
            height: 50px;
            width: 50px;
        }

        &.medium {
            height: 75px;
            width: 75px;
        }

        &.large {
            height: 100px;
            width: 100px;
        }

        &.xlarge {
            height: 200px;
            width: 200px;
        }
    }

    &-square {
        border-radius: $br-default;
        background: $grey;
        margin: $base-margin;
        display: inline-block;

        &.small {
            height: 50px;
            width: 50px;
        }

        &.medium {
            height: 75px;
            width: 75px;
        }

        &.large {
            height: 100px;
            width: 100px;
        }
    }
}