@import "../utilities/variables.scss";
@import "../utilities/mixins.scss";

/****** Sections ******/

section {
    // Base styles
    padding: $base-padding-xxlarge $base-padding;
    width: 100%;

    h1:first-of-type, h2:first-of-type, h3:first-of-type, h4:first-of-type {
        margin-top: 0;
    }
    // Themes
    &.white {
        background: $white;
        border: 0;

        .testimonial {
            &:before {
                color: transparentize($black, .9);
            }
        }

        .flex-direction-nav a {
            color: $black;
        }
    }

    &.border {
        border-bottom: 1px solid $base-border-color;

        .testimonial {
            &:before {
                color: transparentize($black, .9);
            }
        }

        .flex-direction-nav a {
            color: $black;
        }
    }

    &.light {
        background: $light-grey;
        border: 0;

        .testimonial {
            &:before {
                color: transparentize($black, .9);
            }
        }

        .flex-direction-nav a {
            color: $black;
        }
    }

    &.shaded {
        background: $light-grey;
        border: 0;

        .testimonial {
            &:before {
                color: transparentize($black, .9);
            }
        }

        .flex-direction-nav a {
            color: $black;
        }
    }

    &.dark {
        background: $black;
        color: $white;
        border: 0;

        h1, h2, h3, h4, p, a, .lead {
            color: white;
        }

        .card {
            color: $black;
        }

        .agent-shortcard-branded, .card--property {
            color: $black;

            b, h1, h2, h3, h4, h5, a, p {
                color: $black !important;
            }
        }

        .testimonial {
            &:before {
                color: transparentize($white, .8);
            }
        }
    }

    &.primary {
        background: $color-primary;
        color: $white;
        border: 0;

        h1, h2, h3, h4, p, a, .lead {
            color: white;
        }

        .testimonial {
            &:before {
                color: transparentize($white, .8);
            }
        }

        .title-underline {
            &:after {
                background: $white;
            }
        }
    }

    &.dropshadow {
        box-shadow: $base-box-shadow;
        background: $white;
        border: 0;

        .testimonial {
            &:before {
                color: transparentize($black, .9);
            }
        }

        .flex-direction-nav a {
            color: $black;
        }
    }

    &.house-pattern {
        background-image: url('https://s-static.cinccdn.com/img/consumer/house-pattern.png');
        background-repeat: repeat-x;
        background-size: 100%;
    }

    &.padding-small {
        padding: $base-padding;
    }
    &.info {
        background: transparentize($color-blue , .9);
    }

    // Etta Section
    &.app_background {
        background-image: url(https://s-static.cinccdn.com/images/etta/etta_hero_111819.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 180px 20px;
        color: $white;
        h1, h2, h3, h4 {
            color: $white;
        }

        @include mq(small) {
            background-color: $color-etta;
            background-size: contain;
            background-position: top;
            padding-top: 75%;
            h1, h2, h3, h4 {
                font-size: $type-h3;
            }
        }
    }
}