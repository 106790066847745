﻿/****** Icons ******/
@import "../utilities/variables.scss";
@import "../utilities/mixins.scss";

.icon-group {
    text-align: center;
    display: inline-block;
    margin: 0 $base-margin-medium;

    i {
        display: block;
        text-align: center;
        color: $black;
        font-size: $type-h3;
    }

    .thumbnail-round, .thumbnail-square {
        display: block;
        text-align: center;
    }

    span {
        display: block;
        text-align: center;
        color: $dark-grey;
        font-family: $font-family--primary;
        padding-top: $base-padding-xsmall;
    }
    // Themes
    &.horizontal {
        i, .thumbnail-round, .thumbnail-square, span {
            display: inline-block;
            vertical-align: middle;
            padding: 0;
            padding-right: $base-padding-xxsmall;
        }
    }

    &.small-icon {
        i {
            font-size: $type-base;
        }
    }

    &.no-margin {
        margin: 0;
    }

    &.school {
        margin: 0;

        .thumbnail-round, .thumbnail-square {
            padding: 16px 12px;
            color: white;
            font-family: $font-family--primary;
            font-size: 18px;
            margin: 7px;

            &.green {
                background: $color-green;
            }

            &.yellow {
                background: $color-yellow;
            }

            &.red {
                background: $color-red;
            }

            &.xsmall {
                padding: 8px 4px;
                font-size: $type-small;
                font-weight: bold;
                height: 28px;
                width: 28px;
                margin: 2px 0;
                margin-right:0;
            }
        }
    }
}
