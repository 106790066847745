@import "../base/base.scss";
@import "../utilities/variables.scss";
@import "../utilities/mixins.scss";

/****** PDQ ******/

.pdq {
    &.grid__flex {
        @include mq(small) {
            display: flex;
        }

        @include mq(pdq-small) {
            display: block;
        }
    }

    &-sidebar {
        padding: $base-padding;
        background: lighten($light-grey, 5%);

        h4:first-child {
            margin-top: 0;
        }

        @include mq(pdq-small) {
            display: none;
        }
    }

    &-content {
        padding: $base-padding;
        background: darken($light-grey, 2%);

        .container {
            margin: 0;
            padding: 0;
        }

        .pagination {
            a {
                background: $white;
                @extend .link-blue;
            }

            @include mq(small) {
                font-size: $type-small;
            }
        }

        #pdqPagination {
            @include mq(pdq-small) {
                font-size: $type-small;
                margin-bottom: $base-margin;
            }
        }

        #pdqMoreOptionsPanel {
            display: none;
        }

        h3:first-child {
            margin-top: 0;
        }

        @include mq(pdq-small) {
            h3 {
                font-size: $type-base;
            }
        }
    }
}