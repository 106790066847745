﻿@import "../utilities/variables.scss";
@import "../utilities/mixins.scss";

/****** ALERTS ******/

%validation-message {
    padding: $base-padding-xsmall $base-padding-small;
    padding-left: 38px;
    color: $white;
    display:block;
    margin-bottom: $base-margin;
    font-size: 12px;
    border-radius: $br-default;
    position: relative;
}

// .alert {
//     @include solid-buttons($ui-colors);
// }

// [class^="alert--"] {
//     padding: $base-padding;
//     width: 100%;
//     color: $white;
// }

.validation-message {
    &.error {
        background: $color-red;
        @extend %validation-message;
        &:before {
            content:'\e935';
            font-family: icomoon;
            font-size: $type-h4;
            color: $white;
            position: absolute;
            left: 7px;
            top: 0;
        }
    }
    &.success {
        background: $color-green;
        @extend %validation-message;
        &:before {
            content:'\e969';
            font-family: icomoon;
            font-size: $type-h4;
            color: $white;
            position: absolute;
            left: 7px;
            top: 0;
        }
    }
}

.app-banner {
    background: $light-grey;
    width: 100%;
    position:relative;
    padding: $base-padding-xsmall;
    z-index: $z-index-large;
    @include flexbox();
    align-items: center;
    border-bottom: $base-border;
    .close {
        position: relative;
        top:0;
        left:0;
        font-size: $type-small;
        @include flex(0 0 16px);
    }
    &--icon {
        border-radius: 12px;
        background: $white;
        height: 50px;
        @include flex(0 0 50px);
        margin-left: $base-padding;
    }
    &--info {
        font-size: $type-small;
        @include flex(2);
        margin-left: $base-padding;
    }
    &--cta {
        padding-left: $base-padding;
        @include flex(1);
        text-align:right;
        [class^="btn--"] {
            margin-bottom:0;
        }
    }
    @include mq(medium) {
        display:none;
    }
}

.cookie-popup {
    position:fixed;
    bottom:0;
    left:0;
    right:0;
    background: rgba(0,0,0,.9);
    padding: $base-padding;
    color: $white;
    line-height: 1.2;
    @include mq(small) {
        font-size: $type-small;
    }
}

.alert--info {
    background: transparentize($color-blue , .9);
    padding: $base-padding;
    position:relative;
    padding-right: $base-padding-xlarge;
    color: $black;
    @include mq(small) {
        h1, h2, h3, h4 {
            font-size: $type-base;
        }
    }
}


