﻿@import "../utilities/variables.scss";

.ma-5 {
    margin: 5px;
}

.ma-10 {
    margin: 10px;
}

.ma-20 {
    margin: 20px;
}

.m-0 { 
    margin:0px!important;
}

.mb-10 {
    margin-bottom: 10px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-20 {
    margin-top: 20px;
}

.clickable { 
    cursor:pointer;
}

.text-white { 
    color:white;
}

.inline-block {
    display: inline-block;
}
