﻿@import "../utilities/variables.scss";
@import "../utilities/mixins.scss";
@import "../base/base.scss";

/****** Footer ******/

footer {
    padding: $base-padding-xlarge 0;
    padding-bottom: 0;

    .container {
        position: relative;
    }

    a {
        color: $grey;
        @extend %small-uppercase-text;

        &:hover {
            color: $color-primary;
        }
    }

    .logo {
        img {
            max-width: 200px;
            max-height: 200px;
        }
    }

    .terms {
        padding-bottom: $base-padding;

        .logo {
            @include flexbox();
        }

        .links {
            @include flexbox();

            a {
                margin-right: $base-margin-medium;
                font-size: $type-small;
            }
        }
    }

    .disclaimer {
        padding: $base-padding;
        font-size: $type-small;
    }

    @include mq(small) {
        padding: $base-padding;
        text-align: center;

        .disclaimer {
            margin-top: $base-padding;
            margin-left: -$base-padding;
            margin-right: -$base-padding;
            margin-bottom: -$base-padding;
        }
    }
    // Themes
    &.dark {
        background: $black;
        color: $light-grey;

        h4 {
            color: $white;
        }

        .disclaimer {
            color: $grey;
            background: lighten($black, 5%);
        }
    }

    &.light {
        background: $light-grey;
        color: $black;

        .disclaimer {
            color: $grey;
            background: $white;
        }
    }

    &.primary {
        background: $color-primary;
        color: $white;

        a, h4 {
            color: $white;
        }

        .disclaimer {
            color: $grey;
            background: $white;
        }
    }
    // Addon themes
    &.border-top {
        border-top: 4px solid $color-primary;
    }

    &.house-bg {
        background-image: url('house-pattern.png');
    }

    &.city-bg {
        background-image: url('city-pattern.png');
    }
}