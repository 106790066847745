.rate-title {
    font-size: 50px;
    filter: drop-shadow(1px 1px 4px #000000);
    color: white;
    text-indent: each-line;
}
.rate-percent {
    font-size: 38px;
    font-weight: bold;
}
.rate-name{
    font-size: 14px;
    font-weight: bold;
}
.rate-percent-change {
    font-size: 18px;
    font-weight: bold;
}
.rate-logo {
    vertical-align: top !important;
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: landscape) {
    .rate-title {
        font-size: 28px;
    }

    .rate-percent {
        font-size: 28px;
    }

    .rate-name {
        font-size: 12px;
    }

    .rate-percent-change {
        font-size: 14px;
    }
}

@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: portrait) {
    .rate-title {
        font-size: 28px;
    }

    .rate-percent {
        font-size: 28px;
    }

    .rate-name {
        font-size: 12px;
    }

    .rate-percent-change {
        font-size: 14px;
    }
}
