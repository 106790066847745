﻿@import "../utilities/variables.scss";
@import "../utilities/mixins.scss";

/****** Badges ******/

%badge {
	padding: .2em $base-padding-xsmall;
	font-size: .75em;
	font-weight: $font-weight--medium;
	color: $white;
	border-radius: $br-default;
	text-transform: uppercase;
	display: inline-block;
}

.badge {
    &-primary {
        @extend %badge;
        background: $color-primary;
    }
	&-blue {
        @extend %badge;
        background: $color-blue;
    }
    &-red {
        @extend %badge;
        background: $color-red;
    }
}
.badge-float-left {
    position: absolute;
    top:-5px;
    right: -5px;
    left: auto;
}

[class^="badge--"] {
    @extend %badge;
}

.badge {
    // Property status badges
    &--property-status {
        @extend %badge;

        &.active {
            background: $color-green;
        }

        &.pending {
            background: $dark-grey;
        }
    }
    &--virtual-tour {
        @extend %badge;
        background: $dark-grey;
        color: $white;
        vertical-align: middle;
        margin-right: 5px;
    }
}
