@import "../utilities/_progress-bar.scss";
@import "../utilities/_gradients.scss";

//
// Progress bars
// --------------------------------------------------


// Bar animations
// -------------------------

// WebKit
@-webkit-keyframes progress-bar-stripes {
  from  { background-position: 40px 0; }
  to    { background-position: 0 0; }
}

// Spec and IE10+
@keyframes progress-bar-stripes {
  from  { background-position: 40px 0; }
  to    { background-position: 0 0; }
}


// Bar itself
// -------------------------

// Outer container
.progress {
    overflow: hidden;
    height: 30px;
    margin-bottom: 5px;
    background-color: #ebeff2;
    border-radius: 10px;
}

// Bar of progress
.progress-bar {
    float: left;
    width: 0%;
    height: 100%;
    font-size: 17px;
    line-height: 30px;
    color: #fff;
    text-align: center;
    background-color: $color-primary;
}