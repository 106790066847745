﻿@import "../utilities/variables.scss";
@import "../utilities/mixins.scss";
@import "../base/base.scss";

/****** Header ******/

.header {
    // Header base
    width: 100%;
    box-shadow: $base-box-shadow;
    position: relative;
    z-index: $z-index-xlarge; // make header above other elements
    background: $white;

    .header-items {
        @include flexbox();
        align-items: center;

        > div:nth-child(1) {
            @include flex(2);
            @include flexbox();

            @include mq(small) {
                @include flex(100%);
            }
        }

        > div:nth-child(2) {
            @include flex(7);
        }

        .logo {
            @include flex(1);
            display: flex;
            align-items: center;
            padding-right: $base-padding;
            color: $color-primary;
            font-weight: $font-weight--bold;
            font-size: $type-base;

            img {
                max-height: 60px;
                max-width: 160px;
                width: auto;
                margin: $base-padding-xsmall;

                @include mq(small) {
                    max-height: 40px;
                    width: auto;
                    margin: $base-margin;
                    max-width: 100%;
                }
            }

            &.text-accent {
                padding: $base-padding-small 0;
            }

            @include mq(small) {
                font-size: $type-base;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                max-width: 280px;
            }
            // Themes
        }

        .header-menu {
            &-right {
                text-align: right;

                .dropdown-account {
                    overflow: hidden;
                    white-space: nowrap;
                    max-width: 200px;
                    display: inline-block;
                    vertical-align: middle;
                    text-overflow: ellipsis;

                    @include mq(small-md) {
                        max-width: 130px;
                    }
                }

                @include mq(small) {
                    max-width: 100% !important;

                    .btn-outline--default {
                        border-color: $white;
                        width: 100%;
                    }
                }
            }
        }

        @include mq(small) {
            @include flexbox();
            vertical-align: middle;
            margin-left: $base-padding-large;
        }
    }

    .header-item {
        display: inline-block;
        padding: $base-padding-small;
        vertical-align: middle;

        a {
            color: $black;
            font-size: $type-small;
            font-weight: 700;
            letter-spacing: $letter-space;
            text-transform: uppercase;

            &:hover {
                color: $color-primary;
            }
        }

        [class^=btn--], [class^=btn-outline--] {
            margin-bottom: 0;
            display: inline-block;
        }

        &.item-right {
            text-align: right;
            float: right;

            @include mq(small) {
                float: none;
                text-align: left;
            }
        }

        &.more {
            display: none;
        }
    }
    // UI dropdown overrides for header
    .ui {
        &.dropdown {
            .menu {
                @include mq(small) {
                    position: relative;
                    min-width: auto;
                    background: none;
                    box-shadow: none;
                    border: none;
                }
            }

            .item {
                max-width: 300px;
                white-space: normal;
                padding: 0 !important;

                a {
                    width: 100%;
                    padding: $base-padding;
                    display: block;

                    @include mq(small) {
                        padding: $base-padding-small 0;
                    }
                }
            }

            &.link {
                @include mq(small) {
                    width: 100%;
                }

                .dropdown.icon {
                    @include mq(small) {
                        position: absolute;
                        top: $base-padding-small;
                        right: 0;
                        color: $white;
                    }
                }
            }
        }
    }

    .header-icon {
        display: none;
    }
	.icon-more {
        display: none;
    }
    // Mobile State
    @include mq(small) {
        position: static;
        min-height: 55px;

        .header-item {
            display: block;
            padding: $base-padding-small;
            border-bottom: 1px solid transparentize($white, .8);

            &:last-of-type {
                border-bottom: 0;
            }

            a {
                text-transform: initial;
                font-size: $type-base;
                font-weight: normal;

                &:active, &:visited {
                    color: $white;
                }
            }
        }

        .btn--text {
            width: 100%;
        }

        .account-item {
            padding: $base-padding-small;
        }

        .header-menu {
            position: fixed;
            left: 0;
            top: 0;
            bottom: 0;
            background-color: $color-primary;
            width: 250px;
            -webkit-transform: translateX(-250px);
            transform: translateX(-250px);
            padding: $base-padding 0;
            overflow-y: scroll;

            a {
                color: $white;
            }
        }

        .header-icon {
            display: block;
            cursor: pointer;
            width: 50px;
            height: 20px;
            position: absolute;
            transform: translateY(50%);
            left: 0;

            &:after {
                content: '';
                position: absolute;
                display: block;
                width: 1rem;
                height: 0;
                left: 15px;
                box-shadow: 0 10px 0 1px $black, 0 16px 0 1px $black, 0 22px 0 1px $black;
            }
        }
		.icon-more {
                display: block;
				cursor: pointer;
				height: 22px;
				position: absolute;
				transform: translateY(80%);

				&:after {
					position: absolute;
					display: block;
					width: 1rem;
					height: 0;
					left: 15px;
				}
            }
    }
    // Header themes
    &.header-light {
        background: $light-grey;
        border-bottom: 1px solid $base-border-color;

        @include mq(small) {
            .header-icon {
                display: inline-block;
                cursor: pointer;
                width: 50px;
                height: 20px;

                &:after {
                    content: '';
                    position: absolute;
                    display: block;
                    width: 1rem;
                    height: 0;
                    top: 9px;
                    left: 15px;
                    -webkit-box-shadow: 0 10px 0 1px $white, 0 16px 0 1px $white, 0 22px 0 1px $white;
                    box-shadow: 0 10px 0 1px $black, 0 16px 0 1px $black, 0 22px 0 1px $black;
                }
            }
        }
    }

    &.header-dark {
        background: $black;

        .logo {
            color: $color-primary;
        }

        .header-item {
            a {
                color: $white;

                &:hover {
                    color: $color-primary;
                }
            }
        }

        @include mq(small) {
            .header-icon {
                display: inline-block;
                cursor: pointer;
                width: 50px;
                height: 20px;

                &:after {
                    content: '';
                    position: absolute;
                    display: block;
                    width: 1rem;
                    height: 0;
                    top: 9px;
                    left: 15px;
                    -webkit-box-shadow: 0 10px 0 1px $white, 0 16px 0 1px $white, 0 22px 0 1px $white;
                    box-shadow: 0 10px 0 1px $white, 0 16px 0 1px $white, 0 22px 0 1px $white;
                }
            }
        }
    }

    &.header-primary {
        background: $color-primary;

        .logo {
            color: $white;
        }

        .header-item {
            a {
                color: $white;

                &:hover {
                    color: lighten($color-primary, 10%);
                }
            }
        }

        @include mq(small) {
            .header-icon {
                display: inline-block;
                cursor: pointer;
                width: 50px;
                height: 20px;

                &:after {
                    content: '';
                    position: absolute;
                    display: block;
                    width: 1rem;
                    height: 0;
                    top: 9px;
                    left: 15px;
                    -webkit-box-shadow: 0 10px 0 1px $white, 0 16px 0 1px $white, 0 22px 0 1px $white;
                    box-shadow: 0 10px 0 1px $white, 0 16px 0 1px $white, 0 22px 0 1px $white;
                }
            }
        }
    }

    &.bordertop {
        border-top: 4px solid $color-primary;
    }

    &.bordertop-secondary {
        border-top: 4px solid $color-secondary;
    }

    &.header-transparent-home {
        background: transparent;
        box-shadow: none;
        position: absolute;

        .logo {
            color: $white;
        }

        .header-item {
            a {
                color: $white;

                &:hover {
                    opacity: .7;
                }
            }
        }

        .ui.dropdown > .dropdown.icon {
            color: $white;
        }

        @include mq(small) {
            position: relative;

            .logo {
                color: $black;
            }

            .header-item {
                a {
                    color: $black;

                    &:hover {
                        opacity: .7;
                    }
                }
            }

            .ui.dropdown > .dropdown.icon {
                color: $black;
            }
        }
    }

    &.header-back {
        position: relative;
        background: $color-primary;
        color: $white;
        padding: $base-padding-small 0;
        z-index: $z-index-medium;

        a {
            color: $white;
        }
    }
}

// Admin Toolbar (holds bust cache button)

.admin-toolbar {
    padding: $base-padding-xsmall 0;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1f2930+1,33434f+99 */
    background: rgb(123, 189, 236); /* Old browsers */
    background: -moz-linear-gradient(left, rgba(31,41,48,1) 1%, rgba(51,67,79,1) 99%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, rgba(31,41,48,1) 1%,rgba(51,67,79,1) 99%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, rgba(31,41,48,1) 1%,rgba(51,67,79,1) 99%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1f2930', endColorstr='#33434f',GradientType=1 ); /* IE6-9 */
    color: $white;
    @include mq(small) {
        display:none;
    }
}


// Agent Subdomain header

.subdomain-info {
    padding: $base-padding-xsmall;
    text-align:right;
    background: $light-grey;
    .agent-photo {
        border-radius: $br-round;
        background: $grey;
        background-size:cover;
        background-attachment: center;
        display: inline-block;
        height: 25px !important;
        width: 25px !important;
    }
    .agent-phone {
        background: $color-primary;
        color: $white;
        padding: 0 $base-padding-xsmall;
        border-radius: $br-default;
        a[href^="tel"] {
            color: inherit;
            text-decoration: none;
          }
    }
    .agent-social {
        text-align:right;
        @include mq(small) {
            i {
                font-size: $type-h4;
            }
        }
    }
}

// Social Media 

.header-social-media {
    width: 100%;
    font-size: $type-base;
    text-align: right;
    margin-top: $base-padding-small;
    a {
        padding: $base-padding-xsmall;
        color: $grey;
    }
    
}

.lender-mortgage-banner {
    padding: $base-padding-xsmall;
    background: rgb(198,242,216);
    background: -moz-linear-gradient(90deg, rgba(198,242,216,1) 0%, rgba(245,245,245,1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(198,242,216,1) 0%, rgba(245,245,245,1) 100%);
    background: linear-gradient(90deg, rgba(198,242,216,1) 0%, rgba(245,245,245,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#c6f2d8",endColorstr="#f5f5f5",GradientType=1);
    width: 100%;
    color: $black;
    font-size: $type-h4;
    text-align:center;
    position:relative;
    .close {
        position:absolute;
        top:$base-padding;
        right: $base-padding;
        color: $black;
        font-size: $type-base;
    }
}

.header-banner {
    padding: $base-padding-small;
    background: $black;
    color: $white;
    width: 100%;
    text-align: center;

    a {
        color: $white;
        text-decoration: underline;
        margin-left: $base-padding;
    }
}