﻿@import "../utilities/variables.scss";
@import "../utilities/mixins.scss";

/****** GRID SYSTEM (FLEX) ******/

%clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

.container {
    max-width: $g-cont-max-w;
    margin-right: auto;
    margin-left: auto;
    padding: 0 $base-padding;
}

.container-short {
    max-width: ($g-cont-max-w / 2);
    margin-right: auto;
    margin-left: auto;
    padding: 0 $base-padding;
}

/* Vertically Aligned Centered */

.grid__centered {
    align-items: center;
}

.grid__stretch {
    align-items: stretch;
}

.grid__baseline {
    align-items: flex-end;
}

$grid-gutter-percent: 1%;
$grid-gutter: 1;

.grid__flex {
    @include flexbox();
    justify-content: flex-start;
    @include flow(row wrap);
    @extend %clearfix;
    margin: 0;

    &:after {
        width: 0;
    }

    @include mq(small) {
        display: block;
        margin: auto;

        > div {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
        }

        &.no-collapse-mobile {
            display: flex;

            > div {
                width: auto;
            }
        }
    }

    @include mq(small-tablet) {
        &.no-collapse-tablet {
            display: flex;

            > div {
                width: auto;
            }
        }
    }
    /* No Left/Right Margin on Mobile (mainly for property cards) */
    &.no-margin-mobile {
        @include mq(small) {
            margin-right: -$base-margin-medium;
            margin-left: -$base-margin-medium;
        }
    }

    &.grid__searchbar {
        > div {
            &:first-of-type {
                @include flex(0 33%);

                @include mq(small-md) {
                    @include flex(0 25%);
                }
            }

            &:nth-child(4) {
                @include flex(2);

                @include mq(small-md) {
                    @include flex(2 20%);
                }
            }

            @include flex(1);
        }
    }
    /* Even Columns */
    &.grid__2col {
        > div {
            @include flexColumns(2, 0);
        }
    }

    &.grid__3col {
        > div {
            @include flexColumns(3, 0);
        }
    }

    &.grid__4col {
        > div {
            @include flexColumns(4, 0);
        }
    }

    &.grid__5col {
        > div {
            @include flexColumns(5, 0);
        }
    }

    &.grid__6col {
        > div {
            @include flexColumns(6, 0);
        }
    }

    &.grid__7col {
        > div {
            @include flexColumns(7, 0);
        }
    }

    &.grid__8col {
        > div {
            @include flexColumns(8, 0);
        }
    }
    /* Left Sidebar */
    &.grid__1of6 {
        @include flexUnevenColumns($grid-1of6, 0, left);
    }

    &.grid__1of4 {
        @include flexUnevenColumns($grid-1of4, 0, left);
    }

    &.grid__1of3 {
        @include flexUnevenColumns($grid-1of3, 0, left);
    }
    /* Right Sidebar */
    &.grid__1of6--right {
        @include flexUnevenColumns($grid-1of6, 0, right);
    }

    &.grid__1of4--right {
        @include flexUnevenColumns($grid-1of4, 0, right);
    }

    &.grid__1of3--right {
        @include flexUnevenColumns($grid-1of3, 0, right);
    }
    &.grid__fluidcol {
        margin-bottom: $base-padding;
        .flex0 {
            @include flex(0 auto);
        }

        .flex1 {
            @include flex(1);
        }

        .flex2 {
            @include flex(2);
        }

        .flex3 {
            @include flex(3);
        }

        > div:last-of-type {
            margin-right: 0;
        }

        .price-divider {
            @include flex(0);
            margin-right: 6%;
            margin-top: 12px;
        }
    }

    &--gutter {
        @include flexbox();
        justify-content: flex-start;
        @include flow(row wrap);
        @extend %clearfix;
        margin: 0 auto $grid-gutter-percent auto;

        /* Delete below when widgets are re-skinned */
        max-width: $g-cont-max-w;
 

        &:after {
            width: 0;
        }

        @include mq(small) {
            display: block;
            margin: auto;

            > div {
                width: 100%;
                margin-left: 0;
                margin-right: 0;
            }

            &.no-collapse-mobile {
                display: flex;

                > div {
                    width: auto;
                }
            }
        }

        @include mq(small-tablet) {
            &.no-collapse-tablet {
                display: flex;

                > div {
                    width: auto;
                }
            }
        }

        @include mq(small-md) {
            &.collapse-smallmd {
                display: block;
            }
        }
        /* No Left/Right Margin on Mobile (mainly for property cards) */
        &.no-margin-mobile {
            @include mq(small) {
                margin-right: -$base-margin-medium;
                margin-left: -$base-margin-medium;
            }
        }

        &.no-bottom-margin {
            margin-bottom: 0;

            > div {
                margin-bottom: 0 !important;
            }
        }
        /* No Defined Columns */
        &.grid__fluidcol {
            margin-bottom: $base-padding;

            .flex0 {
                @include flex(0 auto);
                margin-right: $grid-gutter-percent;
            }

            .flex1 {
                @include flex(1);
                margin-right: $grid-gutter-percent;
            }

            .flex2 {
                @include flex(2);
                margin-right: $grid-gutter-percent;
            }

            .flex3 {
                @include flex(3);
                margin-right: $grid-gutter-percent;
            }

            > div:last-of-type {
                margin-right: 0;
            }

            .price-divider {
                @include flex(0);
                margin-right: 6%;
                margin-top: 12px;
            }
        }
        /* Header Columns */
        &.grid__header {
            width: 95%;

            > div {
                @include flex(1);
                margin-right: $grid-gutter-percent;

                &:first-of-type {
                    @include flex(0 20%);

                    @include mq(small-md) {
                        @include flex(0 25%);
                    }
                }

                &:nth-child(2) {
                    @include flex(3);

                    @include mq(small-md) {
                        @include flex(2);
                    }
                }

                &:last-of-type {
                    margin-right: 0;
                }
            }
        }
        /* Even Columns */
        &.grid__2col {
            > div {
                @include flexColumns(2, $grid-gutter-percent);
            }
        }

        &.grid__3col {
            > div {
                @include flexColumns(3, $grid-gutter-percent);
            }
        }

        &.grid__4col {
            > div {
                @include flexColumns(4, $grid-gutter-percent);
            }
        }

        &.grid__5col {
            > div {
                @include flexColumns(5, $grid-gutter-percent);
            }
        }

        &.grid__6col {
            > div {
                @include flexColumns(6, $grid-gutter-percent);
            }
        }

        &.grid__7col {
            > div {
                @include flexColumns(7, $grid-gutter-percent);
            }
        }

        &.grid__8col {
            > div {
                @include flexColumns(8, $grid-gutter-percent);
            }
        }
        /* Left Sidebar */
        &.grid__1of6 {
            @include flexUnevenColumns($grid-1of6, $grid-gutter-percent, left);
        }

        &.grid__1of4 {
            @include flexUnevenColumns($grid-1of4, $grid-gutter-percent, left);
        }

        &.grid__1of3 {
            @include flexUnevenColumns($grid-1of3, $grid-gutter-percent, left);
        }
        /* Right Sidebar */
        &.grid__1of6--right {
            @include flexUnevenColumns($grid-1of6, $grid-gutter-percent, right);
        }

        &.grid__1of4--right {
            @include flexUnevenColumns($grid-1of4, $grid-gutter-percent, right);
        }

        &.grid__1of3--right {
            @include flexUnevenColumns($grid-1of3, $grid-gutter-percent, right);
        }
    }
}

// Inline Grid (inline-block)

.grid__inline {
    margin: 0 auto 0 auto;
    max-width: $g-cont-max-w;

    > div {
        display: inline-block;
        margin-right: $grid-gutter-percent;
        vertical-align: middle;

        @include mq(small) {
            display: block;
            width: 100%;
        }
    }

    @include mq(small) {
        &.no-collapse-mobile {
            > div {
                display: inline-block;
                width: auto;
            }
        }
    }
    &.grid__centered {
        > div {
            vertical-align: middle;
        }
    }
    &.grid__1of2 {
        > div {
            display: inline-block;
            margin-right: $grid-gutter-percent;
            vertical-align: middle;

            &:last-of-type {
                width: 50%;
            }
        }
    }
}

// Homepage Searchbar Grid 

.hero-searchbar {
    .autocomplete {
        top: 105px;
    }

    .lender.autocomplete {
        top: 100%!important;
    }
}
.grid__fluidcol.searchbar-grid {
    > div {
        @include flex(1);
        margin-right: $grid-gutter-percent;
    }
    @include mq(small) {
        flex-wrap: wrap;
        .flex2 {
            @include flex(100%);
        }
        > div {
            @include flex(100% 0);
            margin:0;
        }
    }
}

.two-col {
    display: flex;
    flex-wrap: wrap;

    .row-col {
        flex: 0 50%;
    }
}

@media (max-width: 768px) and (min-width: 1px) {
    .two-col {
        .row-col {
            flex: 0 100%;
        }
    }
}