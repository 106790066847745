@import "../utilities/variables.scss";
@import "../utilities/mixins.scss";

.houses-search-promo-banner {
    width: 100%;
    padding-top: 17.53%; /* 1:1 Aspect Ratio */
    position: relative; /* If you want text inside of it */
    text-align:center;
}


.lender-search-banner {
    padding: $base-padding-small;
    background: darken($light-grey, 10%);
    color: $black;

    .banner-copy {
        display: inline-block;
        width: 70%;
        vertical-align: middle;

        @include mq(small) {
            width: 50%;
        }
    }

    .banner-cta {
        text-align: right;
        display: inline-block;
        width: 28%;
        vertical-align: middle;

        @include mq(small) {
            width: 48%;
        }
    }
}

.cut-text {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 160px;
    height: 1.2em;
    white-space: nowrap;
}
