@import "../utilities/variables.scss";
@import "../utilities/mixins.scss";

/****** Notes ******/

.notes {
    &-window {
        height: 200px;
        width: 100%;
        overflow-y: scroll;
        background: transparent;
    }

    .note-bubble {
        @include flexbox();
        padding: $base-padding 0;
        padding-right: $base-padding-small;
        border-bottom: $base-border;

        &:last-of-type {
            border-bottom: 0;
        }

        .note-user-photo {
            @include flex(0);
            width: 50px;
            height: 50px;
            margin-right: $base-margin-medium;
        }

        .note-message {
            @include flex(1);
            vertical-align: top;
        }

        .note-timestamp {
            font-size: $type-small;
            color: $grey;
            margin-top: $base-margin;
        }
    }
}
