@import "../utilities/variables.scss";
@import "../utilities/mixins.scss";

/****** Property Detail ******/

// Individual classes and ids used only on the property details page. They are mainly used to change how elements look on mobile

#detailsContainer {

    #detailSubDetails {
        padding-right: $base-padding-large;
        .details-provided-by {
            padding: 12px 0;
            border-bottom: $base-border;
        }
    }

    #detailSidebar {
        .accordion:first-of-type {
            border-top: 0;
        }
    }

    @include mq(small-md) {
        display: block;
        margin: 0 $base-padding-xlarge;

        #detailSidebar {
            @include flex(100%);
            max-width: 100%;
            background: $white;
        }

        #detailSubDetails {
            padding-right: 0;
        }
    }

    @include mq(small) {
        .sidebar {
            margin-bottom: $base-margin-large;
            padding: 0;
            border-top: $base-border;
            background: $white;
        }

        #detailSidebar {
            @include flex(100%);
            max-width: 100%;
        }

        #detailSubDetails {
            padding-right: 0;
        }
    }
}

.jumbotron-toggle {
    display: inline-block;

    @include mq(small) {
        text-align: left;
        position: relative;
        top: 0;
        right: 0;
        padding-top: $base-padding;
    }
}

.details {
    display: inline-block;
    padding-right: $base-padding;

    &-price {
        font-size: $type-h3;

        .regular-price {
            font-size: $type-base;
            text-decoration: line-through;
            display:block;
            margin-bottom: -12px;
        }

        @include mq(small) {
            position: absolute;
            color: white;
            font-size: $type-h4;
            top: 230px;
            left: $base-padding;

            .mortgage-calc-link {
                display: block;
                font-size: $type-small;

                a {
                    color: $white;
                }
            }

            .regular-price {
                display: inline-block;
                margin-bottom:0;
            }
        }

        @include mq(small-tablet) {
            position: absolute;
            color: white;
            font-size: $type-h4;
            top: 280px;
            left: $base-padding;

            span {
                display: block;
                font-size: $type-small;

                a {
                    color: $white;
                }
            }
        }
    }

    &-address {
        @include mq(small) {
            font-size: $type-h4;
            width: 60%;
        }
    }

    &-info {
        display: inline-block;
        text-align: left;
        padding-top: $base-padding;

        @include mq(small) {
            float: none;
            padding-top: $base-padding;

            .icon-group {
                &:first-of-type {
                    margin-left: 0;
                }
            }
        }
    }

    &-int {
        display: inline-block;

        > div {
            display: inline-block;
        }

        @include mq(small) {
            position: absolute;
            top: 275px;
            right: $base-padding;

            .icon-group {
                padding: .9em;
                border-radius: $br-round;
                background: $white;
                box-shadow: $base-box-shadow;
                display: inline-block;
                height: 44px;
                width: 44px;
                text-align: center;
                transition: box-shadow $base-transition;
                margin: 0;

                i {
                    font-size: $type-base;
                }

                span {
                    display: none;
                }
            }
        }

        @include mq(small-tablet) {
            position: absolute;
            top: 325px;
            right: $base-padding;

            .icon-group {
                padding: .9em;
                border-radius: $br-round;
                background: $white;
                box-shadow: $base-box-shadow;
                display: inline-block;
                height: 44px;
                width: 44px;
                text-align: center;
                transition: box-shadow $base-transition;
                margin: 0;

                i {
                    font-size: $type-base;
                }

                span {
                    display: none;
                }
            }
        }
    }

    &-interactions {
        text-align: right;

        @include mq(small) {
            text-align: left;
        }
    }

    &-nearby {
        .nearby-list {
            height: 300px;
            overflow-y: scroll;
            margin-top: $base-margin-medium;

            @include mq(small) {
                height: auto;
                overflow: auto;
            }
        }

        .list__simple {
            @include mq(small) {
                order: 2;
                flex: 100% !important;
            }
        }

        > #map {
            @include mq(small) {
                order: 1;
                height: 200px;
                @include flex(100%);
            }
        }
    }

    &-mortgage {
        position: relative;

        #chartContainer {
            width: 350px;
            height: 350px;
            margin: auto;

            @include mq(small) {
                height: 294px;
            }
        }

        .payment {
            position: absolute;
            top: 55%;
            left: 50%;
            transform: translate(-50%, -50%);

            @include mq(small) {
                #paymentText {
                    font-size: $type-h4;
                }
            }
        }
    }

    &-estimate-mortgage {
        padding: $base-padding;
        background: $xlight-grey;
        border-bottom: $base-border;
    }

    &-toolbar {
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
        background: $color-primary;
        padding: $base-padding-small;
        z-index: $z-index-medium;

        .btn--white {
            margin-bottom: 0;
            color: $black;
            width: 100%;

            &:visited {
                background: $white;
                color: $black;
            }
        }
    }

    &-provided-by {
        padding: 3px 12px;
        font-size: $type-base;
    }

    // This contains the 'back to search' and 'listing provided by' text
    &-nav-header {
        padding: $base-padding-xsmall;
        background: $light-grey;
        @include mq(small) {
            [data-castle-component=ListingProvidedByComponent], [data-castle-component=EmptyComponent] {
                order: 3;
                flex: 100% !important;
                text-align:center;
            }
        }
        
    }
}

.details-map {
    height: 600px;
    display: none;
    @include mq(small) {
        height: 250px;
    }
}

.mortgage-calculator {
    @include mq(small) {
        display: flex;

        > div:first-child {
            order: 2;
            max-width: 100%;
            @include flex(0 100%);
        }

        > div:last-child {
            order: 1;
            max-width: 100%;
            @include flex(0 100%);
        }
    }
}

.signup-copy {
    background: #F5F5F5;
    padding: 38px;
    flex:1;
    text-align:center;
  }
  
  .signup-form {
    padding: 28px;
    flex:1;
    position:relative;
  }
  
  .signup-copy img {
    max-width: 100%;
    max-height: 300px;
  }
  
  [data-signup-test='1'] {
    width: 60% !important;
    max-width: 700px;
    padding:0 !important;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }
  
  .signup-copy p {
    font-size: 16px !important;
    text-align:center;
  }
  
  [data-signup-test='1'] .modal-social-buttons, [data-signup-test='1'] .modal-header, [data-signup-test='1'] .js-signup-step > p.center {
    display:none;
  }
  
  @media screen and (max-width: 670px) {
    [data-signup-test='1'] {
      width: 95% !important;
      padding:0 !important;
      display:block;
    }
    .signup-copy {
      padding: 16px 28px;
    }
    .signup-form h4 {
      margin-top:0;
    }
    .signup-copy img {
      display:none;
    }
  } 
  
  @media (min-width:376px) and (max-width: 768px) {
    [data-signup-test='1'] {
      width: 80% !important;
      padding:0 !important;
    }
    .signup-copy {
      padding: 16px 28px;
    }
    .signup-form h4 {
      margin-top:0;
    }
  }