@import "../utilities/variables.scss";
@import "../utilities/mixins.scss";
@import "../base/grid.scss";
@import "../modules/cards.scss";

/* MIXINS */

$g-col-width    : 75px !default;
$g-gutter-width : 24px !default;
$g-col-count    : 12 !default;
$g-cont-max-w   : 1250px !default;

$content-max-width : 1500px;

@function g-context($g-col-width, $g-col-count ,$g-gutter-width) {
    $g-context: ($g-col-width * $g-col-count) + ($g-gutter-width * ($g-col-count - 1));
    @return $g-context;
  }
@mixin m($modifier) {
    &--#{$modifier} {
        @content;
    }
}

@mixin position($pos-type, $top: null, $right: null, $bottom: null, $left: null) {
    position: $pos-type;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
}

@mixin size($value) {
    $width: nth($value, 1);
    $height: $width;

    @if length($value) > 1 {
        $height: nth($value, 2);
    }

    @if is-size($height) {
        height: $height;
    }

    @else {
        @warn "`#{$height}` is not a valid length for the `$height` parameter in the `size` mixin.";
    }

    @if is-size($width) {
        width: $width;
    }

    @else {
        @warn "`#{$width}` is not a valid length for the `$width` parameter in the `size` mixin.";
    }
}

@mixin card {
    background-color: white;
    border-radius: $br-default;
    border: 1px solid $base-border-color;
    margin: 0 0 $base-margin-large 0;
    position: relative;

    h1 {
        margin-top: 0;
    }

    .note-preview {
        max-height: 300px;
        margin-top: 1em;
    }

    #map_canvas {
        width: 100%;
        height: 350px;
    }

    ul {
        margin:0;
        padding:0;
    }

    &-image {
        .overlay {
            background: rgba(0,0,0,0);
            background: -moz-linear-gradient(top, rgba(0,0,0,0) 48%, rgba(0,0,0,0.25) 65%, rgba(0,0,0,0.76) 100%);
            background: -webkit-gradient(left top, left bottom, color-stop(48%, rgba(0,0,0,0)), color-stop(65%, rgba(0,0,0,0.25)), color-stop(100%, rgba(0,0,0,0.76)));
            background: -webkit-linear-gradient(top, rgba(0,0,0,0) 48%, rgba(0,0,0,0.25) 65%, rgba(0,0,0,0.76) 100%);
            background: -o-linear-gradient(top, rgba(0,0,0,0) 48%, rgba(0,0,0,0.25) 65%, rgba(0,0,0,0.76) 100%);
            background: -ms-linear-gradient(top, rgba(0,0,0,0) 48%, rgba(0,0,0,0.25) 65%, rgba(0,0,0,0.76) 100%);
            background: linear-gradient(to bottom, rgba(0,0,0,0) 48%, rgba(0,0,0,0.25) 65%, rgba(0,0,0,0.76) 100%);
            height: 220px;
        }

        overflow: hidden;
        height: 200px;
        border-top-left-radius: $br-default;
        border-top-right-radius: $br-default;
    }

    &-wrap {
        padding: em($base-padding-large);

        @include mq(small) {
            padding: em($base-padding);
        }
    }

    &-header {
        font-weight: $font-weight--medium;
        font-size: $type-h4;
        margin-bottom: $base-margin;

        span {
            font-size: $type-base;
            float: right;

            a {
                color: palette(grey, light);
                cursor: pointer;

                &:hover {
                    color: darken($grey, 5%);
                }
            }
        }
    }

    &-copy {
        font-size: $type-base;

        p {
            margin: 0 0 ($base-padding / 2);
        }

        hr {
            margin: $base-margin 0;
            border-color: $light-grey;
        }
    }

    .grid {
        margin: 0;
        padding: 0;
        width: 100% !important;
    }

    .scorecard {
        .rating {
            .attribute {
                padding: $base-padding 0 0 $base-padding-large;

                @include mq (small) {
                    padding-left: $base-padding;
                }

                input {
                    margin-left: -5px;
                }
            }
        }
    }
}

@mixin clearfix() {
    &:before,
    &:after {
        content: " "; // 1
        display: table; // 2
    }

    &:after {
        clear: both;
    }
}

@mixin doubly($margin: 1em) {
    & + & {
        margin-left: $margin;
        @content;
    }
}

$context: g-context($g-col-width, $g-col-count, $g-gutter-width);

@mixin g-columns {
    @for $i from 1 through $g-col-count {
        $target: ($g-col-width * $i) + ($g-gutter-width * ($i - 1));

        // Generate and output the column modifier classes
        &--#{$i} {
            width: percentage($target / $context);
        }
    }
}

/* BASE */

.h1 {
    @extend h3;
}

hr {
    width: 100px;
    margin: $base-padding auto;
    border: 1px solid $base-border-color;

}

// Use the split-box method to create a symmetrical grid. You only need a class on the containing element.

$split-limit: 24;

@mixin split-box {
    @for $i from 1 to $split-limit {
        .split__#{$i} {
            & > * {
                $width: 1 / $i;
                width: percentage($width);
                margin: 0;
                float: left;

                @include mq(small-md) {
                    width: 50%;
                }

                @include mq(small) {
                    width: 100%;
                }
            }
        }
    }
}

@include split-box;

[class^="split__"] {
    height: auto;
    overflow: auto;

    & > * {
        float: left;
    }
}

// PADDING

@mixin box-pad($pad-amount) {
    padding: $pad-amount;
}

$pad-base: 4px;

.pad {
    @include m(none) {
        @include box-pad(0);
    }

    @include m(tight) {
        & > * {
            @include box-pad(0);
        }
    }

    @include m(xsmall) {
        & > * {
            @include box-pad($pad-base);
        }
    }

    @include m(small) {
        & > * {
            @include box-pad($pad-base * 3);
        }
    }

    @include m(medium) {
        & > * {
            @include box-pad($pad-base * 4);
        }
    }

    @include m(large) {
        & > * {
            @include box-pad($pad-base * 8);
        }
    }
}

/* NEIGHBORHOOD GUIDES */

%extend_1 {
    pointer-events: none;
}

%extend_2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

%extend_3 {
    margin: 0;
}


.city-photo figure {
    position: relative;
    float: left;
    overflow: hidden;
    margin: 0;
    width: 100%;
    height: 300px;
    background: $color-primary;
    text-align: center;
    cursor: pointer;

    img {
        position: relative;
        display: block;
        min-height: 100%;
        min-width: 100%;
        object-fit: cover;
        opacity: 0.8;
    }

    figcaption {
        padding: 2em;
        color: #fff;
        text-transform: uppercase;
        font-size: $type-h4;
        backface-visibility: hidden;
        @extend %extend_2;

        > a {
            @extend %extend_2;
            z-index: 1000;
            text-indent: 200%;
            white-space: nowrap;
            font-size: 0;
            opacity: 0;
        }
    }

    figcaption::before {
        @extend %extend_1;
    }

    figcaption::after {
        @extend %extend_1;
    }

    h2 {
        font-weight: $font-weight--light;
        font-size: $type-h4;
        color: white;
        font-weight: 400;
        @extend %extend_3;

        span {
            font-weight: 800;
        }
    }

    p {
        @extend %extend_3;
        font-size: 12px;
        text-transform: none;
    }
}

/* AGENT CARDS */

.agent-shortcard-branded {
    @extend .card;
    position: relative;
    background: white;
    background: palette(theme,card-bg-color);
    color: palette(theme, text-basic);
    margin-bottom: 1em;
    border: 1px solid $base-border-color;
    padding: $base-padding-xlarge $base-padding;
    -webkit-box-shadow: 1px 1px 3px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 1px 1px 3px 0px rgba(0,0,0,0.1);
    box-shadow: 1px 1px 3px 0px rgba(0,0,0,0.1);
    overflow: hidden;

    .agent-image {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        margin: auto;
    }

    .agent-info {
        padding: $base-padding-medium $base-padding;
        width: 100%;
        text-align: center;

        h3 {
            margin: 0;
            font-size: $type-h4;
            text-transform: none;
            color: $color-primary;
        }

        .agent-title {
            color: $grey;
        }

        .agent-contact {
            font-size: $type-small;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 100%;
            overflow: hidden;
        }
    }
}

/* PROPERTY CARDS */

.card--property {
    @include card;
    cursor: pointer;
    border: 0;
    border-radius: $br-default;
    margin-bottom: 0;
    background: white;
    border: $base-border;
    -webkit-box-shadow: 1px 1px 3px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 1px 1px 3px 0px rgba(0,0,0,0.1);
    box-shadow: 1px 1px 3px 0px rgba(0,0,0,0.1);

    @include mq(small) {
        height: 250px;
        margin-bottom: 0;
    }

    p {
        color: $black;
    }

    &-image {
        position: relative;
        height: 200px;

        .link-overlay {
            position: absolute;
            width: 50%;
            top: 0;
            height: 100%;
            left: 25%;
            z-index: 3;
        }

        .link-overlay-full {
            width: 100%;
            left: 0;
        }

        .photocount {
            margin: 0;
            position: absolute;
            top: 170px;
            left: 0;
            padding: 5px;
            background-color: transparentize($white, 0.1);
            color: $black;
            text-align: center;
            z-index: 1;
        }

        .action {
            position: absolute;
            z-index: 5;
            color: white;

            &.js-togglefavorite {
                padding: $base-padding-small;
                top: 12px;
                right: 10px;
                font-size: $type-h4;
            }
        }

        .fotorama-overlay {
            top: 0;
            left: 0;
            right: 0;
            position: absolute;
            background: rgba(0,0,0,.3);
            height: 200px;
        }

        &::after {
            content: '';
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.2) linear-gradient(to bottom, transparent 48%, rgba(0, 0, 0, 0.25) 65%, rgba(0, 0, 0, 0.50) 100%) !important;
            z-index: 0;
            position: absolute;
        }

        @include mq(small) {
            height: 250px;
        }
    }

    &-wrap {
        padding: $base-padding;

        @include mq(small) {
            display: none;
        }
    }

    &-copy {
        text-align: center;

        a, p {
            color: $black;
        }
    }

    &-stats {
        position: absolute;
        bottom: 0px;
        left: 0px;
        top: 135px;
        right: 0;
        padding: 0px;
        z-index: 1;

        .price {
            padding: 0 $base-padding;
            color: white;
            font-size: $type-base;
            text-shadow: 1px 1px 3px rgba(0,0,0,.5);
            display: inline-block;
            font-weight: $font-weight--medium;
            font-size: $type-h4;
        }

        li {
            text-align: left;
            display: inline-block;
            padding: 0 6px;
            font-size: $type-base;
            color: white;
            width: auto;
            text-shadow: 1px 1px 4px $black;

            span {
                display: inline-block;
                padding-left: 3px;
                color: white;
            }

            &:first-child {
                padding-left: $base-padding;
            }

            &:last-of-type {
                border-right: 0;
            }
        }

        @include mq(small) {
            top: 120px;

            ul {
                &.full-address {
                    li {
                        width: 100%;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
    }

    &-disclaimer {
        text-align: left;
        color: $black;
        font-size: $type-xs;
        width: 100%;
        margin-top: 18px;
        text-align: center;

        a {
            color: $black;
        }

        img, small {
            display: inline-block;
            vertical-align: middle;
            float: none;
        }

        @include mq(small) {
            text-align: left;
            position: absolute;
            bottom: $base-padding-small;

            li {
                > div {
                    font-size: $type-xs;
                    display: inline-block;
                    margin-left: 5px;
                    opacity: .8;
                    width: 70%;
                    vertical-align: middle;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }

    .property-img-wrapper {
        @include mq(small) {
            height: 250px;
        }
    }

    .stats-under-image {
        .card--property-stats {
            position: relative;
        }
    }
    /* FOR MLS's that require no information on images */
    &.card-no-overlay {
        .price {
            padding: 0 $base-padding;
            color: $black;
            text-shadow: none;
            text-align: center;
            font-weight: $font-weight--medium;
            font-size: $type-h4;
        }

        .ribbon-wrapper .ribbon {
            position: relative;
            display: inline-block;
            top: 0;
            left: 0;
        }

        .card--property-image:after {
            background: transparent !important;
        }

        .card--property-stats {
            overflow-x: hidden;
            position: relative;
            top: 0px;
            padding: 0px;
            text-align: center;

            li {
                text-align: center;
                display: inline-block;
                padding: 0 6px;
                font-size: $type-base;
                color: $grey;
                width: auto;
                text-shadow: none;

                span {
                    display: inline-block;
                    padding-left: 3px;
                    color: $grey;
                }

                &:first-child {
                    padding-left: $base-padding;
                }

                &:last-of-type {
                    border-right: 0;
                }
            }
        }

        @include mq(small) {
            height: auto;
            margin-bottom: $base-padding-medium;

            .card--property-wrap {
                display: block;
            }

            .card--property-disclaimer {
                position: relative;
            }
        }

        // Also have all fonts to have the same color and size
        &.mls-color-font-same-style {
            .price {
                font-size: $type-base;
                font-weight: initial;
            }
            .card--property-copy p {
                color: $black !important;
                font-size: $type-base;
            }
            .card--property-stats {
                li {
                    color: $black;
                    font-size: $type-base;
                    span {
                        color: $black;
                    }
                }
            }
            .card--property-info p {
                color: $black !important;
                font-size: $type-base;
            }
        }
    }

    &.simple-gallery {
        /*.overlay {
            height: unset !important;
            width: unset;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.2) linear-gradient(to bottom, transparent 48%, rgba(0, 0, 0, 0.25) 65%, rgba(0, 0, 0, 0.50) 100%) !important;
            position: absolute;
            z-index: 2;
        }*/
        .ribbon-wrapper {
            z-index: 3;
        }

        .card--property-image {
            position: relative;

            .link-overlay {
                left: 0;
                height: 200px;
                top: 0;
                width: 100%;
                z-index: 3;
            }
        }

        &.has-ribbon {
            .image-load-error .fa-chain-broken {
                margin-top: 32px;
            }
        }
    }
}

.image-load-error,
.property-image-loading {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: $light-grey;
    text-align: center;
}

.image-load-error {
    color: rgba(0,0,0,0.7);
    padding: 19px 0 0 10px;

    .fa-chain-broken {
        color: rgba(0,0,0,0.5);
        display: block;
    }
}

.property-image-loading {
    line-height: 200px;
    text-align: center;
    z-index: -1;
}

.property-img-wrapper {
    position: relative;
    overflow: hidden;
    height: 200px;

    img {
        position: absolute;
        top: -100%;
        left: -100%;
        right: -100%;
        bottom: -100%;
        margin: auto;
        min-height: 200px;
        width: 100%;
    }

    &.load-error {
        display: none;
    }

    &.loaded .property-image-loading {
        display: none;
    }
}

.fa-spin-custom, .glyphicon-spin {
    -webkit-animation: spin 1000ms infinite linear;
    animation: spin 1000ms infinite linear;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

/* RIBBONS */

.ribbon-wrapper {
    pointer-events: none;

    .ribbon {
        color: white;
        font-size: $type-small;
        font-weight: $font-weight--medium;
        line-height: $base-line;
        padding: 2px $base-padding-xsmall;
        position: absolute;
        text-align: center;
        top: $base-padding;
        left: $base-padding;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        text-transform: uppercase;
        z-index: 1;
        max-width: 50%;
        border-radius: 3px;
    }
}

.ribbon {
    background: $color-green;
}

/* LENDER */

#lender-card-image {
    max-width: 100%;
}

#lender-card-info {
    width: auto;
    padding: $base-padding;
    text-align: left;
    vertical-align: top;
}

#lender-page-header {
    padding: 1.25em 0 0 0;
}

#lender-card-social-links {
    margin: 5px 0 0 0;

    .social-link {
        margin: 0 5px 0 0;
    }
}

#lender-header-image {
    max-height: 230px;
    margin: 0 0 1em;
}

/* TESTIMONIAL SLIDER */

.slideshow {
    position:relative;
}
article.slide-Testimonial {
    background: rgba(0, 0, 0, .3);
    color: white;
    height: 100%;
    padding-top: 100px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    @include mq(small-md) {
        padding: 60px 15% 0 15%;
    }

    @include mq(small) {
        padding: 5px 20px 0 20px;

        [class^="btn--"] {
            width: auto;
            margin: auto;
            padding: 6px 12px;
        }
    }

    h2 {
        font-size: unset;

        .feature-caption {
            color: white;
            font-size: $type-h1;
            font-family: $font-family--primary;
            text-transform: none;
            font-weight: 300;

            @include mq(small) {
                font-size: $type-h4;
            }

            @include mq(small-md) {
                font-size: $type-h2;
            }
        }

        span {
            font-size: $type-h3;
            color: $white;
            text-transform: none;

            @include mq(small) {
                font-size: $type-base;
            }
        }

        hr {
            width: 100px;
            margin: 10px auto;
        }
    }
}

.testimonial-slide {
    max-width: 800px;
    margin:auto;
}

/* INPUTS */

.form-control {
    display:block;
    width: 100%;
}
.js-inquiry-form {
    padding: $base-padding;
}

/* MODAL */

.modal-open {
    overflow: hidden;
    position: fixed;
    width: 100%;
}

.modal {
    ///////////////////////////////////////////////////////////////////////////////////
    h1 {
        margin: 0;
    }

    p {
        color: $black;
        line-height: $base-line;
    }
    //////////////////////////////////////////////////////////////////////////////////

    $modal-padding: 3em;
    $modal-background: $white;
    $modal-close-color: $light-grey;
    $modal-image-height: 135px;
    $modal-image-width: $modal-image-height;
    $modal-trigger-image-width: 300px;

    label {
        cursor: pointer;
        margin-bottom: 0;
    }

    label img {
        border-radius: $modal-trigger-image-width/2;
        display: block;
        max-width: $modal-trigger-image-width;
    }

    input[type="checkbox"] {
        display: none;
    }

    .modal-trigger {
        width: auto;
        color: $color-primary;
    }

    .modal-open {
        overflow: auto;
    }

    .modal-window { // overlay
        @include position(fixed, 0, 0, 0, 0);
        transition: opacity $trns-duration;
        background: rgba(0,0,0, .6);
        opacity: 0;
        text-align: left;
        visibility: hidden;
        z-index: 999998;
        padding-bottom: 5em;
        overflow-y: auto;


        .modal-bg {
            position: absolute;
            top: 0;
            right: 0;
            cursor: pointer;
        }
    }

    .modal-close {
        @include position(absolute, $modal-padding /2, $modal-padding /2, null, null);
        @include size(1em);
        background: $modal-background;
        cursor: pointer;

        &:after,
        &:before {
            @include position(absolute, 3px, 3px, 0, 50%);
            @include vendor-prefix(transform, rotate(45deg));
            @include size(.15em 1em);
            background: $modal-close-color;
            content: '';
            display: block;
            margin: -3px 0 0 -1px;
        }

        &:hover:after,
        &:hover:before {
            background: darken($modal-close-color, 10);
        }

        &:before {
            @include vendor-prefix(transform, rotate(-45deg));
        }
    }

    .modal-close-2 {
        @include position(absolute, 7em, 2em, null, null);
    }

    .modal-login {
        @include position(absolute, $modal-padding /2, $modal-padding /2, null, null);
        padding: 5px 10px;
        background: $color-primary;
        border-radius: $br-default;
        color: $white;
        border: 1px solid $base-border-color;
    }

    .modal-inner {
        transition: opacity $trns-duration;
        background: $modal-background;
        border-radius: 5px;
        margin-top: .6em;
        margin: auto;
        max-height: 95%;
        overflow-y: visible;
        overflow-x: hidden;
        padding: $modal-padding / 2;
        position: relative;

        label {
            display: initial;
        }

        hr {
            border: 1px solid $base-border-color;
            margin: 15px 0;
        }

        p {
            margin: 15px 0 15px 0;
            font-size: $type-small;

            &.modal-question {
                font-size: $type-base !important;
                color: $black;
                font-weight: bold;
            }
        }

        .form-control {
            background: $light-grey;
            color: $black;
            width: 100%;
            margin: 10px auto;
        }

        .modal-header {
            background: $white;
            text-align: center;
            border-top-left-radius: 0.5em;
            border-top-right-radius: 0.5em;
            font-size: $type-base;
            margin: -1.8em -3.5em 1em -3.5em;
            padding: 1em 3em;
            border-bottom: 1px solid $base-border-color;

            h2 {
                font-size: 28px;
                font-weight: 400;
                color: $black;
                text-transform: none;
                margin: 0;
                padding: 0;
            }
        }

        .modal-footer {
            background: $white;
            text-align: left;
        }

        a {
            color: #09C;
        }

        input[type="checkbox"] {
            display: initial;
        }
        //Signup Or
        .signup-separator {
            background: none;
            height: 30px;
            position: relative;
            text-align: center;

            .text {
                background-color: $white;
                border-radius: $br-round;
                display: inline-block;
                margin: 0;
                padding: 5px;
            }

            hr {
                margin: -18px 0;
                border-color: #aaa;
            }
        }

        .btn--link {
            background: none;
            text-transform: unset;
            letter-spacing: 0;
            padding-bottom: 0;
        }

        .form__select {
            font-size: 16px;
            margin-bottom: 18px;
            border-radius: $br-default;
            background: $white;
            color: palette(grey, base);
            font-weight: $font-weight--medium;
            border: 1px solid $light-grey;
            -webkit-appearance: none;
            padding: 8px 10px;
            width: 100%;
        }

        @include mq(small) {
            h2 {
                font-size: $type-base;
            }

            .form-control {
                font-size: $type-base;
                line-height: 18px;
                padding: $base-padding-xsmall;
            }

            .modal-header {
                padding: .7em 3em;
            }

            .checkbox {
                margin: 0;
            }
        }

        @include mq(medium) {
            margin-top: 1em;
            padding: $modal-padding/2;
            width: 60%;
        }

        @include mq(large) {
            width: 60%;
        }
        /* SIGN IN MODAL */
        &.signin {
            background-color: #F2F2F2;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: left center;
            width: 700px;
            max-height: 95%;
            overflow: hidden;
            font-size: $type-base;

            h1 {
                font-size: $type-h3;
                text-align: center;
                margin-top: 20px;
            }


            @include mq(small) {
                width: 100%;
            }

            [class^="btn--"] {
                font-size: $type-small !important;
                font-weight: $font-weight--bold;
            }

            .info {
                li {
                    display: inline-block;
                    vertical-align: top;
                    margin-bottom: 20px;

                    &:first-child {
                        width: 20%;
                        height: 40px;
                    }

                    &:last-child {
                        width: 75%;
                    }
                }
            }

            .signup-separator {
                .text {
                    background: #eaebea;
                }
            }
        }


        &.box {
            max-width: 448px;
            width: auto;
            margin-top: 10vh;
            overflow: hidden;
            max-height: 95%;

            @include mq(small) {
                width: 90%;
                margin-top: 0;
            }
        }

        .lead {
            font-size: 18px;
        }

        .js-loginmessage {
            padding: 5px 10px;
            font-size: 18px;
            margin-bottom: 20px;
            color: black;
        }

        h1 {
            color: $black;
            margin-bottom: .6em;
            text-align: left;
            text-transform: capitalize;
        }

        h2 {
            margin-top: 0;
            margin-bottom: 5px;
        }

        .modal-intro {
            font-weight: $font-weight--bold;
        }

        .modal-content {
            color: $black;
        }

        .modal-footer {
            background: $light-grey;
            text-align: right;
            border-bottom-left-radius: $br-default;
            border-bottom-right-radius: $br-default;
            font-size: $type-small;
            margin: 1em -3.5em -1.8em -3.5em;
            padding: $modal-padding/2 $modal-padding;
            border-top: 1px solid $base-border-color;

            a {
                color: $black;
            }
        }


        a.cta {
            color: white;
            display: inline-block;
            margin-right: .5em;
            margin-top: 1em;

            &:last-child {
                padding: 0 2em;
            }
        }

        &.modal-legacy {
            width: 400px;

            @media (max-width: 603px) and (min-width: 1px) {
                width: 100%;
            }

            @media only screen and (min-device-width : 375px) and (max-device-width : 667px) and (orientation : landscape) {
                width: 400px;
            }

            .checkbox {
                @include mq(small) {
                    margin-bottom: 0;
                }
            }

            p {
                color: $black;
                font-weight: normal;
            }

            @include mq(small) {
                padding: 1.5em 1.2em;

                .modal-header {
                    h2 {
                        font-size: 18px;
                    }
                }

                p {
                    font-size: $type-small;
                    margin-left: -0.75em;
                    margin-right: -0.75em;
                    color: $black;
                    font-weight: normal;
                }
            }

            @media (max-width: 480px) and (min-width: 1px) {
                padding: 1.5em 1.2em;

                .modal-header {
                    h2 {
                        font-size: 18px;
                    }
                }

                p {
                    font-size: 10px;
                    margin-left: -0.75em;
                    margin-right: -0.75em;
                    color: $black;
                    font-weight: normal;
                }
            }
        }
    }

    .modal-state:checked + .modal-window {
        opacity: 1;
        visibility: visible;
    }

    .modal-state:checked + .modal-window .modal-inner {
        top: .5em;
    }
}

// Old Modal

.modal-open {
    overflow: hidden;
    position: fixed;
}

.modal {
    ///////////////////////////////////////////////////////////////////////////////////
    h1 {
        margin: 0;
    }

    p {
        color: $black;
        line-height: $base-line;
    }
    //////////////////////////////////////////////////////////////////////////////////

    $modal-padding: 3em;
    $modal-background: $white;
    $modal-close-color: $light-grey;
    $modal-image-height: 135px;
    $modal-image-width: $modal-image-height;
    $modal-trigger-image-width: 300px;

    label {
        cursor: pointer;
        margin-bottom: 0;
    }

    label img {
        border-radius: $modal-trigger-image-width/2;
        display: block;
        max-width: $modal-trigger-image-width;
    }

    input[type="checkbox"] {
        display: none;
    }

    .modal-trigger {
        width: auto;
        color: $color-primary;
    }

    .modal-open {
        overflow: auto;
    }

    .modal-window { // overlay
        @include position(fixed, 0, 0, 0, 0);
        transition: opacity $trns-duration;
        background: rgba(0,0,0, .8);
        opacity: 0;
        text-align: left;
        visibility: hidden;
        z-index: 999998;
        padding-bottom: 5em;
        overflow-y: auto;


        .modal-bg {
            position: absolute;
            top: 0;
            right: 0;
            cursor: pointer;
        }
    }

    .modal-close {
        @include position(absolute, $modal-padding /2, $modal-padding /2, null, null);
        @include size(1em);
        background: $modal-background;
        cursor: pointer;

        &:after,
        &:before {
            @include position(absolute, 3px, 3px, 0, 50%);
            @include vendor-prefix(transform, rotate(45deg));
            @include size(.15em 1em);
            background: $modal-close-color;
            content: '';
            display: block;
            margin: -3px 0 0 -1px;
        }

        &:hover:after,
        &:hover:before {
            background: darken($modal-close-color, 10);
        }

        &:before {
            @include vendor-prefix(transform, rotate(-45deg));
        }
    }

    .modal-close-2 {
        @include position(absolute, 7em, 2em, null, null);
    }

    .modal-login {
        @include position(absolute, $modal-padding /2, $modal-padding /2, null, null);
        padding: 5px 10px;
        background: $color-primary;
        border-radius: $br-default;
        color: $white;
        border: 1px solid $base-border-color;
    }

    .modal-inner {
        transition: opacity $trns-duration;
        background: $modal-background;
        border-radius: 5px;
        margin-top: .6em;
        margin: auto;
        max-height: 95%;
        overflow-y: visible;
        overflow-x: hidden;
        padding: $modal-padding / 2;
        position: relative;

        label {
            display: initial;
        }

        hr {
            border: 1px solid $base-border-color;
            margin: 15px 0;
        }

        p {
            margin: 15px 0 15px 0;
            font-size: $type-small;

            &.modal-question {
                font-size: $type-base !important;
                color: $black;
                font-weight: bold;
            }
        }

        .form-control {
            background: $light-grey;
            color: $black;
            width: 100%;
            margin: 10px auto;
        }

        .btn--social {
            height: 29px;
            width: 241px;
            padding:0;
            @include mq(small) {
                width: 100%;
                height: 29px;
            }
        }

        .modal-header {
            background: $white;
            text-align: center;
            border-top-left-radius: 0.5em;
            border-top-right-radius: 0.5em;
            font-size: $type-base;
            margin: -1.8em -3.5em 1em -3.5em;
            padding: 1em 3em;
            border-bottom: 1px solid $base-border-color;

            h2 {
                font-size: 28px;
                font-weight: 400;
                color: $black;
                text-transform: none;
                margin: 0;
                padding: 0;
            }
        }

        .modal-footer {
            background: $white;
            text-align: left;
        }

        // a {
        //     color: #09C;
        // }

        input[type="checkbox"] {
            display: initial;
        }
        //Signup Or
        .signup-separator {
            background: none;
            height: 30px;
            position: relative;
            text-align: center;

            .text {
                background-color: $white;
                border-radius: $br-round;
                display: inline-block;
                margin: 0;
                padding: 5px;
            }

            hr {
                margin: -18px 0;
                border-color: #aaa;
            }
        }

        @include mq(small) {
            h2 {
                font-size: $type-base;
            }

            .form-control {
                font-size: $type-base;
                line-height: 18px;
                padding: $base-padding-xsmall;
            }

            .modal-header {
                padding: .7em 3em;
            }

            .checkbox {
                margin: 0;
            }
        }

        @include mq(medium) {
            margin-top: 1em;
            padding: $modal-padding/2;
            width: 60%;
        }

        @include mq(large) {
            width: 60%;
        }
        /* SIGN IN MODAL */
        &.signin {
            background-color: #F2F2F2;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: left center;
            width: 700px;
            max-height: 95%;
            overflow: hidden;
            font-size: $type-base;

            h1 {
                font-size: $type-h3;
                text-align: center;
                margin-top: 20px;
            }


            @include mq(small) {
                width: 100%;
            }

            [class^="btn--"] {
                font-size: $type-small !important;
                font-weight: $font-weight--bold;
            }

            .info {
                li {
                    display: inline-block;
                    vertical-align: top;
                    margin-bottom: 20px;

                    &:first-child {
                        width: 20%;
                        height: 40px;
                    }

                    &:last-child {
                        width: 75%;
                    }
                }
            }

            .signup-separator {
                .text {
                    background: #eaebea;
                }
            }
        }


        &.box {
            max-width: 448px;
            width: auto;
            margin-top: 10vh;
            overflow: hidden;
            max-height: 95%;

            @include mq(small) {
                width: 90%;
                margin-top: 0;
            }
        }

        .lead {
            font-size: 18px;
        }

        .js-loginmessage {
            padding: 5px 10px;
            font-size: 18px;
            margin-bottom: 20px;
            color: black;
        }

        h1 {
            color: $type-base;
            margin-bottom: .6em;
            text-align: left;
            text-transform: capitalize;
        }

        h2 {
            margin-top: 0;
            margin-bottom: 5px;
        }

        .modal-intro {
            font-weight: $font-weight--bold;
        }

        .modal-content {
            color: $type-base;
        }

        .modal-footer {
            background: $light-grey;
            text-align: right;
            border-bottom-left-radius: $br-default;
            border-bottom-right-radius: $br-default;
            font-size: $type-small;
            margin: 1em -3.5em -1.8em -3.5em;
            padding: $modal-padding/2 $modal-padding;
            border-top: 1px solid $base-border-color;

            a {
                color: $black;
            }
        }


        a.cta {
            color: white;
            display: inline-block;
            margin-right: .5em;
            margin-top: 1em;

            &:last-child {
                padding: 0 2em;
            }
        }

        &.modal-legacy {
            width: 400px;

            @media (max-width: 603px) and (min-width: 1px) {
                width: 100%;
            }

            @media only screen and (min-device-width : 375px) and (max-device-width : 667px) and (orientation : landscape) {
                width: 400px;
            }

            .checkbox {
                @include mq(small) {
                    margin-bottom: 0;
                }
            }

            p {
                color: $black;
                font-weight: normal;
            }

            @include mq(small) {
                padding: 1.5em 1.2em;

                .modal-header {
                    h2 {
                        font-size: 18px;
                    }
                }

                p {
                    font-size: $type-small;
                    margin-left: -0.75em;
                    margin-right: -0.75em;
                    color: $black;
                    font-weight: normal;
                }
            }

            @media (max-width: 480px) and (min-width: 1px) {
                padding: 1.5em 1.2em;

                .modal-header {
                    h2 {
                        font-size: 18px;
                    }
                }

                p {
                    // font-size: 10px;
                    margin-left: -0.75em;
                    margin-right: -0.75em;
                    color: $black;
                    font-weight: normal;
                }
            }
        }
    }

    .modal-state:checked + .modal-window {
        opacity: 1;
        visibility: visible;
    }

    .modal-state:checked + .modal-window .modal-inner {
        top: .5em;
    }
}


.supersecret {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;

    .supersecret-signin {
        margin-top: .83333em;
        margin-bottom: 0;
    }
}

.modal-checkboxes {
    .checkbox {
        display: inline-block;
        margin: 0;
        padding-right: 10px;

        input, label {
            font-size: 16px;
        }

        &.wide {
            margin-right:40px;
        }
    }
}

// Tooltipstered

/* This is the default Tooltipster theme (feel free to modify or duplicate and create multiple themes!): */
.tooltipster-default {
    border-radius: 5px;
    border: 1px solid darken($black, 10%);
    background: $black;
    color: $white;
}

/* Use this next selector to style things like font-size and line-height: */
.tooltipster-default .tooltipster-content {
    font-family: Arial, sans-serif;
    font-size: $type-base;
    line-height: $base-line;
    padding: 8px 10px;
    overflow: hidden;
}

/* This next selector defines the color of the border on the outside of the arrow. This will automatically match the color and size of the border set on the main tooltip styles. Set display: none; if you would like a border around the tooltip but no border around the arrow */
.tooltipster-default .tooltipster-arrow .tooltipster-arrow-border {
    /* border-color: ... !important; */
}


/* If you're using the icon option, use this next selector to style them */
.tooltipster-icon {
    cursor: help;
    margin-left: 4px;
}








/* This is the base styling required to make all Tooltipsters work */
.tooltipster-base {
    padding: 0;
    font-size: 0;
    line-height: 0;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9999999;
    pointer-events: none;
    width: auto;
    overflow: visible;
}

.tooltipster-base .tooltipster-content {
    overflow: hidden;
}


/* These next classes handle the styles for the little arrow attached to the tooltip. By default, the arrow will inherit the same colors and border as what is set on the main tooltip itself. */
.tooltipster-arrow {
    display: block;
    text-align: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.tooltipster-arrow span, .tooltipster-arrow-border {
    display: block;
    width: 0;
    height: 0;
    position: absolute;
}

.tooltipster-arrow-top span, .tooltipster-arrow-top-right span, .tooltipster-arrow-top-left span {
    border-left: 8px solid transparent !important;
    border-right: 8px solid transparent !important;
    border-top: 8px solid;
    bottom: -7px;
}

.tooltipster-arrow-top .tooltipster-arrow-border, .tooltipster-arrow-top-right .tooltipster-arrow-border, .tooltipster-arrow-top-left .tooltipster-arrow-border {
    border-left: 9px solid transparent !important;
    border-right: 9px solid transparent !important;
    border-top: 9px solid;
    bottom: -7px;
}

.tooltipster-arrow-bottom span, .tooltipster-arrow-bottom-right span, .tooltipster-arrow-bottom-left span {
    border-left: 8px solid transparent !important;
    border-right: 8px solid transparent !important;
    border-bottom: 8px solid;
    top: -7px;
}

.tooltipster-arrow-bottom .tooltipster-arrow-border, .tooltipster-arrow-bottom-right .tooltipster-arrow-border, .tooltipster-arrow-bottom-left .tooltipster-arrow-border {
    border-left: 9px solid transparent !important;
    border-right: 9px solid transparent !important;
    border-bottom: 9px solid;
    top: -7px;
}

.tooltipster-arrow-top span, .tooltipster-arrow-top .tooltipster-arrow-border, .tooltipster-arrow-bottom span, .tooltipster-arrow-bottom .tooltipster-arrow-border {
    left: 0;
    right: 0;
    margin: 0 auto;
}

.tooltipster-arrow-top-left span, .tooltipster-arrow-bottom-left span {
    left: 6px;
}

.tooltipster-arrow-top-left .tooltipster-arrow-border, .tooltipster-arrow-bottom-left .tooltipster-arrow-border {
    left: 5px;
}

.tooltipster-arrow-top-right span, .tooltipster-arrow-bottom-right span {
    right: 6px;
}

.tooltipster-arrow-top-right .tooltipster-arrow-border, .tooltipster-arrow-bottom-right .tooltipster-arrow-border {
    right: 5px;
}

.tooltipster-arrow-left span, .tooltipster-arrow-left .tooltipster-arrow-border {
    border-top: 8px solid transparent !important;
    border-bottom: 8px solid transparent !important;
    border-left: 8px solid;
    top: 50%;
    margin-top: -7px;
    right: -7px;
}

.tooltipster-arrow-left .tooltipster-arrow-border {
    border-top: 9px solid transparent !important;
    border-bottom: 9px solid transparent !important;
    border-left: 9px solid;
    margin-top: -8px;
}

.tooltipster-arrow-right span, .tooltipster-arrow-right .tooltipster-arrow-border {
    border-top: 8px solid transparent !important;
    border-bottom: 8px solid transparent !important;
    border-right: 8px solid;
    top: 50%;
    margin-top: -7px;
    left: -7px;
}

.tooltipster-arrow-right .tooltipster-arrow-border {
    border-top: 9px solid transparent !important;
    border-bottom: 9px solid transparent !important;
    border-right: 9px solid;
    margin-top: -8px;
}


/* Some CSS magic for the awesome animations - feel free to make your own custom animations and reference it in your Tooltipster settings! */

.tooltipster-fade {
    opacity: 0;
    -webkit-transition-property: opacity;
    -moz-transition-property: opacity;
    -o-transition-property: opacity;
    -ms-transition-property: opacity;
    transition-property: opacity;
}

.tooltipster-fade-show {
    opacity: 1;
}

.tooltipster-grow {
    -webkit-transform: scale(0,0);
    -moz-transform: scale(0,0);
    -o-transform: scale(0,0);
    -ms-transform: scale(0,0);
    transform: scale(0,0);
    -webkit-transition-property: -webkit-transform;
    -moz-transition-property: -moz-transform;
    -o-transition-property: -o-transform;
    -ms-transition-property: -ms-transform;
    transition-property: transform;
    -webkit-backface-visibility: hidden;
}

.tooltipster-grow-show {
    -webkit-transform: scale(1,1);
    -moz-transform: scale(1,1);
    -o-transform: scale(1,1);
    -ms-transform: scale(1,1);
    transform: scale(1,1);
    -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
    -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
    -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
    -ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
    -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
}

.tooltipster-swing {
    opacity: 0;
    -webkit-transform: rotateZ(4deg);
    -moz-transform: rotateZ(4deg);
    -o-transform: rotateZ(4deg);
    -ms-transform: rotateZ(4deg);
    transform: rotateZ(4deg);
    -webkit-transition-property: -webkit-transform, opacity;
    -moz-transition-property: -moz-transform;
    -o-transition-property: -o-transform;
    -ms-transition-property: -ms-transform;
    transition-property: transform;
}

.tooltipster-swing-show {
    opacity: 1;
    -webkit-transform: rotateZ(0deg);
    -moz-transform: rotateZ(0deg);
    -o-transform: rotateZ(0deg);
    -ms-transform: rotateZ(0deg);
    transform: rotateZ(0deg);
    -webkit-transition-timing-function: cubic-bezier(0.230, 0.635, 0.495, 1);
    -webkit-transition-timing-function: cubic-bezier(0.230, 0.635, 0.495, 2.4);
    -moz-transition-timing-function: cubic-bezier(0.230, 0.635, 0.495, 2.4);
    -ms-transition-timing-function: cubic-bezier(0.230, 0.635, 0.495, 2.4);
    -o-transition-timing-function: cubic-bezier(0.230, 0.635, 0.495, 2.4);
    transition-timing-function: cubic-bezier(0.230, 0.635, 0.495, 2.4);
}

.tooltipster-fall {
    top: 0;
    -webkit-transition-property: top;
    -moz-transition-property: top;
    -o-transition-property: top;
    -ms-transition-property: top;
    transition-property: top;
    -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
    -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
    -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
    -ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
    -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
}

.tooltipster-fall-show {
}

.tooltipster-fall.tooltipster-dying {
    -webkit-transition-property: all;
    -moz-transition-property: all;
    -o-transition-property: all;
    -ms-transition-property: all;
    transition-property: all;
    top: 0px !important;
    opacity: 0;
}

.tooltipster-slide {
    left: -40px;
    -webkit-transition-property: left;
    -moz-transition-property: left;
    -o-transition-property: left;
    -ms-transition-property: left;
    transition-property: left;
    -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
    -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
    -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
    -ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
    -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
}

.tooltipster-slide.tooltipster-slide-show {
}

.tooltipster-slide.tooltipster-dying {
    -webkit-transition-property: all;
    -moz-transition-property: all;
    -o-transition-property: all;
    -ms-transition-property: all;
    transition-property: all;
    left: 0px !important;
    opacity: 0;
}


/* CSS transition for when contenting is changing in a tooltip that is still open. The only properties that will NOT transition are: width, height, top, and left */
.tooltipster-content-changing {
    opacity: 0.5;
    -webkit-transform: scale(1.1, 1.1);
    -moz-transform: scale(1.1, 1.1);
    -o-transform: scale(1.1, 1.1);
    -ms-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1);
}

// Old Grid

.grid {
	@extend %clearfix;
	@extend .centered;
	width: 95%;
	margin-bottom: em(20px);
    @include mq(small) {
        width: 98%;
    }

	// Make nested grid container 100%
	[class*="grid__col--"] > & {
		width: 100%;
	}

	// Set max-width for grid container
	@include mq(large) {
		max-width: $g-cont-max-w;
	}
}

// Generates grid columns

.grid__col {
    @include mq(medium) {
        @include g-columns;
    }
}

// Column styles



[class^="grid__col--"] {
    @include mq(small) {
        margin-top: 8px;
        margin-bottom: 8px;
    }

    @include mq(medium) {
        @include doubly(percentage($g-gutter-width / $context));
        float: left;
        min-height: 1px;
        padding-left: 0;
        padding-right: 0;

        &:last-of-type {
            float: right;
        }
    }
}

.centered {
	float: none;
	margin-left: auto;
	margin-right: auto;
}

