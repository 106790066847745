@import "../utilities/variables.scss";
@import "../utilities/mixins.scss";

/****** STATES ******/

.disabled {
    opacity: .65;
    pointer-events: none;
}

.full-width {
    width: 100%;
}

.open {
    display: block;
}

.hidden {
    display: none;
}

.no-bottom-margin {
    margin-bottom: 0 !important;
}



/****** MODIFIERS ******/

.padding-small {
    padding: $base-padding-small;
}

.padding-medium {
    padding: $base-padding-medium;
}

.padding-large {
    padding: $base-padding-large;
}

/****** DEVICE STATES ******/

.is-hidden-mobile {
    @include mq(small) {
        display: none !important;
    }
}

.is-displayed-mobile {
    @include mq(medium) {
        display: none !important;
    }
}

.hidden-xs {
    @include mq(small) {
        display: none !important;
    }
}

.hidden-sm {
    @include mq(small-md) {
        display: none !important;
    }
}

.hidden-md {
    @include mq(medium) {
        display: none !important;
    }
}

.hidden-lg {
    @include mq(large) {
        display: none !important;
    }
}