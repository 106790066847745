@import "../utilities/variables.scss";
@import "../utilities/mixins.scss";

/****** SCHEDULE SHOWING ******/

$image-margin: 9px;

.schedule-showing {
    height: auto;
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: $base-margin 0;

    .gallery-container {
        white-space: nowrap;
        position: relative;
        width: 100%;
    }

    .slide {
        margin: 0;
        padding: 0;
        list-style-type: none;
        font-size: 0;
        display: inline-block;
        padding-right: 28px;
        padding-left: 28px;

        .tags-checkbox {
            display: inline-block;
            margin-right: $image-margin;
            position: relative;
            width: calc(33% - (#{$image-margin} / 3));
            text-align: center;
            font-size: $type-base;

            label {
                width: 100%;
            }
        }
    }

    .gallery-arrow {
        font-size: $type-h4;
        color: $black;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        z-index: 1;

        &.left {
            left: 0;
        }

        &.right {
            right: 0;
        }

        &.faded {
            opacity: .75;
        }
    }
}

