@import "../utilities/variables.scss";
@import "../utilities/mixins.scss";

.home-val {
    margin: auto;
    max-width: 900px;
    @include mq(small) {
        margin-left: -$base-padding-medium;
        margin-right: -$base-padding-medium;
    }
}

.home-val-map {
    height: 300px;
    position: relative;
    background-position: center;
    background-size: cover;
    &:after {
        height: 300px;
        content: '';
        position:absolute;
        top: 0;
        left:0;
        right:0;
        bottom:0;
        background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(130,130,130,0) 49%, rgba(0,0,0,0.8) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(130,130,130,0) 49%,rgba(0,0,0,0.8) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(130,130,130,0) 49%,rgba(0,0,0,0.8) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#80000000',GradientType=0 ); /* IE6-9 */
    }
    .address-overlay {
        position:absolute;
        bottom: 0;
        left: $base-padding;
        right: $base-padding;
        padding: $base-padding;
        color: $white;
        z-index:1;
        h3 {
            margin: 0;
            color: $white;
        }
        .home-val-rooms {
            margin-right: $base-padding-large;
            font-size: $type-base;
            @include mq(small) {
                font-size: $type-base;
            }
        }
        @include mq(small) {
            padding:0;
        }
    }
    @include mq(small) {
        background-size: auto;
    }
}

.home-val-active-buyers {
    background: $color-primary;
    color: $white;
    padding: $base-padding;
    font-size: $type-h4;
    text-align: center;
}

.home-val-cash-offer {
    background: $white;
	padding: $base-padding;
}

.home-val-opendoor {
    background-color: $white;
    border-bottom: $base-border;
    .opendoor-text {
        padding: $base-padding-large;
    }
    .opendoor-img {
        background-image: url(https://s-static.cinccdn.com/img/consumer/opendoor-img.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top right;
    }
}