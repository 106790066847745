﻿/****** MIXINS *******/
@mixin vendor-prefix($name, $argument) {
    -webkit-#{$name}: #{$argument};
    -ms-#{$name}: #{$argument};
    -moz-#{$name}: #{$argument};
    -o-#{$name}: #{$argument};
    #{$name}: #{$argument};
}

@mixin m($modifier) {
    &--#{$modifier} {
        @content;
    }
}

// Flexbox Mixins

@mixin flexbox() {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

@mixin flex($values) {
    -webkit-box-flex: $values;
    -moz-box-flex: $values;
    -webkit-flex: $values;
    -ms-flex: $values;
    flex: $values;
}

@mixin flex-direction($values) {
    -webkit-flex-direction: $values;
    -ms-flex-direction: $values;
    flex-direction: $values;
}

@mixin order($val) {
    -webkit-box-ordinal-group: $val;
    -moz-box-ordinal-group: $val;
    -ms-flex-order: $val;
    -webkit-order: $val;
    order: $val;
}

@mixin flow($wrapstyle) {
    -webkit-flex-flow: $wrapstyle;
    -moz-flex-flow: $wrapstyle;
    -ms-flex-flow: $wrapstyle;
    flex-flow: $wrapstyle;
}

// Flex grid

@mixin flexColumns($numPerRow, $margin) {
    -webkit-flex: 0 ((100% - (($numPerRow - 1) * $margin)) / $numPerRow);
    -ms-flex: 0 ((100% - (($numPerRow - 1) * $margin)) / $numPerRow);
    flex: 0 ((100% - (($numPerRow - 1) * $margin)) / $numPerRow);

    &:nth-child(n) {
        margin-right: $margin;
        margin-bottom: $margin;
        max-width: ((100% - (($numPerRow - 1) * $margin)) / $numPerRow);

        @include mq(small) {
            max-width: 100%;
        }
    }

    &:nth-child(#{$numPerRow}n) {
        margin-right: 0;
        max-width: ((100% - (($numPerRow - 1) * $margin)) / $numPerRow);

        @include mq(small) {
            max-width: 100%;
        }
    }

    @if $numPerRow == 3 {
        @media (min-width: $brkpoint-sm) and (max-width: $brkpoint-md) {
            $numPerRow: 2;
            -webkit-flex: 0 ((100% - (($numPerRow - 1) * $margin)) / $numPerRow);
            -ms-flex: 0 ((100% - (($numPerRow - 1) * $margin)) / $numPerRow);
            flex: 0 ((100% - (($numPerRow - 1) * $margin)) / $numPerRow);

            &:nth-child(n) {
                margin-right: $margin;
                margin-bottom: $margin;
                max-width: ((100% - (($numPerRow - 1) * $margin)) / $numPerRow);

                @include mq(small) {
                    margin-left: 0;
                    margin-right: 0;
                    max-width: 100%;
                }
            }

            &:nth-child(#{$numPerRow}n) {
                margin-right: 0;
                max-width: ((100% - (($numPerRow - 1) * $margin)) / $numPerRow);

                @include mq(small) {
                    margin-left: 0;
                    margin-right: 0;
                    max-width: 100%;
                }
            }
        }
    }

    @if $numPerRow == 4 {
        @media (min-width: $brkpoint-sm) and (max-width: $brkpoint-lg) {
            $numPerRow: 2;
            -webkit-flex: 0 ((100% - (($numPerRow - 1) * $margin)) / $numPerRow);
            -ms-flex: 0 ((100% - (($numPerRow - 1) * $margin)) / $numPerRow);
            flex: 0 ((100% - (($numPerRow - 1) * $margin)) / $numPerRow);

            &:nth-child(n) {
                margin-right: $margin;
                margin-bottom: $margin;
                max-width: ((100% - (($numPerRow - 1) * $margin)) / $numPerRow);

                @include mq(small) {
                    margin-left: 0;
                    margin-right: 0;
                    max-width: 100%;
                }
            }

            &:nth-child(#{$numPerRow}n) {
                margin-right: 0;
                max-width: ((100% - (($numPerRow - 1) * $margin)) / $numPerRow);

                @include mq(small) {
                    margin-left: 0;
                    margin-right: 0;
                    max-width: 100%;
                }
            }
        }
    }
}

@mixin flexUnevenColumns($colWidth, $margin, $alignment) {
    @if $alignment == 'left' {
        > div {
            @include flex(1);
            margin-right: $margin;

            &:first-of-type {
                -webkit-box-flex: 0 $colWidth;
                -moz-box-flex: 0 $colWidth;
                -webkit-flex: 0 $colWidth;
                -ms-flex: 0 $colWidth;
                flex: 0 $colWidth;
                max-width: $colWidth;

                @include mq(small) {
                    max-width: 100% !important;
                }
            }

            &:last-of-type {
                margin-right: 0;
            }

            @if $colWidth == $grid-1of3 {
                $colWidth: 50%;

                @media (min-width: $brkpoint-sm) and (max-width: $brkpoint-lg) {
                    &:first-of-type {
                        -webkit-box-flex: 0 $colWidth;
                        -moz-box-flex: 0 $colWidth;
                        -webkit-flex: 0 $colWidth;
                        -ms-flex: 0 $colWidth;
                        flex: 0 $colWidth;
                        max-width: $colWidth;
                    }
                }

                @include mq(small) {
                    max-width: 100%;
                }
            }

            @else if $colWidth == $grid-1of4 {
                $colWidth: 33%;

                @media (min-width: $brkpoint-sm) and (max-width: $brkpoint-lg) {
                    &:first-of-type {
                        -webkit-box-flex: 0 $colWidth;
                        -moz-box-flex: 0 $colWidth;
                        -webkit-flex: 0 $colWidth;
                        -ms-flex: 0 $colWidth;
                        flex: 0 $colWidth;
                        max-width: $colWidth;
                    }
                }

                @include mq(small) {
                    max-width: 100%;
                }
            }
        }
    }
    @else if $alignment == 'right' {
        > div {
            @include flex(1);
            margin-right: $margin;

            &:last-of-type {
                -webkit-box-flex: 0 $colWidth;
                -moz-box-flex: 0 $colWidth;
                -webkit-flex: 0 $colWidth;
                -ms-flex: 0 $colWidth;
                flex: 0 $colWidth;
                max-width: $colWidth;
                margin-right: 0;

                @include mq(small) {
                    max-width: 100%;
                }

                &:last-of-type {
                    margin-right: 0;
                }
            }

            @if $colWidth == $grid-1of3 {
                $colWidth: 50%;

                @media (min-width: $brkpoint-sm) and (max-width: $brkpoint-lg) {
                    &:last-of-type {
                        -webkit-box-flex: 0 $colWidth;
                        -moz-box-flex: 0 $colWidth;
                        -webkit-flex: 0 $colWidth;
                        -ms-flex: 0 $colWidth;
                        flex: 0 $colWidth;
                        max-width: $colWidth;
                        margin-right: 0;
                    }
                }

                @include mq(small) {
                    max-width: 100%;
                }
            }
            @else if $colWidth == $grid-1of4 {
                $colWidth: 33%;

                @media (min-width: $brkpoint-md) and (max-width: $brkpoint-lg) {
                    &:last-of-type {
                        -webkit-box-flex: 0 $colWidth;
                        -moz-box-flex: 0 $colWidth;
                        -webkit-flex: 0 $colWidth;
                        -ms-flex: 0 $colWidth;
                        flex: 0 $colWidth;
                        max-width: $colWidth;
                    }
                }

                @include mq(small) {
                    max-width: 100%;
                }
            }
        }
    }
}

// Clearfix

@mixin clearfix() {
    &:before,
    &:after {
        content: " "; // 1
        display: table; // 2
    }

    &:after {
        clear: both;
    }
}

// Media queries

@mixin mq($break) {
    @if $break == "small" {
        @media (min-width: $brkpoint-sm) and (max-width: $brkpoint-md) {
            @content;
        }
    }
    @else if $break == "small-landscape" {
        @media screen and (max-width: $brkpoint-md) and (orientation: landscape) {
            @content;
        }
    }
    @else if $break == "small-md" {
        @media (min-width: $brkpoint-md + 1) and (max-width: $brkpoint-lg) {
            @content;
        }
    }
    @else if $break == "medium" {
        @media (min-width: $brkpoint-md + 1) {
            @content;
        }
    }
    @else if $break == "medium-landscape" {
        @media screen and (min-width: $brkpoint-md + 1) and (max-width: 1024px) and (orientation: landscape) {
            @content;
        }
    }
    @else if $break == "large" {
        @media (min-width: $brkpoint-lg) {
            @content;
        }
    }
    @else if $break == "x-large" {
        @media (min-width: $brkpoint-xl) {
            @content;
        }
    }
    @else if $break == "pdq-small" {
        @media (min-width: $brkpoint-sm) and (max-width: 680px) {
            @content;
        }
    }
    @else if $break == "small-tablet" {
        @media (min-width: 600px) and (max-width: $brkpoint-md) {
            @content;
        }
    }
    @else {
        @error "No value could be retrieved for `#{$break}`. "
    }
}

// Generate buttons colors

@mixin solid-buttons($map, $p-el: null) {
    @each $theme, $color in $map {
        @include m($theme) {
            background-color: $color;

            &:hover {
                background-color: darken($color, 10%);
                color: $white;
            }
        }
    }
}

@mixin outline-buttons($map, $p-el: null) {
    @each $theme, $color in $map {
        @include m($theme) {
            border: 2px solid $color;

            &:hover {
                border-color: darken($color, 10%);
                color: $black;
            }
        }
    }
}

@mixin angle($pseudo, $flip: false, $angle: 1.5deg) {
    // Possible values for $pseudo are: before, after, both
    @if $pseudo == 'before' or $pseudo == 'after' or $pseudo == 'both' {
        position: relative;
        z-index: 1;
        $selector: if($pseudo == 'both', '&:before,&:after', '&:#{$pseudo}');

        #{$selector} {
            background: inherit;
            content: '';
            display: block;
            height: 50%;
            left: 0;
            position: absolute;
            right: 0;
            z-index: -1;
            -webkit-backface-visibility: hidden; // for Chrome Windows
        }

        @if $pseudo == 'before' {
            #{$selector} {
                top: 0;

                @if $flip {
                    transform: skewY($angle * -1);
                    transform-origin: 0 0;
                }
                @else {
                    transform: skewY($angle);
                    transform-origin: 100% 0;
                }
            }
        }

        @if $pseudo == 'after' {
            #{$selector} {
                bottom: 0;

                @if $flip {
                    transform: skewY($angle);
                    transform-origin: 0 100%;
                }
                @else {
                    transform: skewY($angle * -1);
                    transform-origin: 100%;
                }
            }
        }

        @if $pseudo == 'both' {
            &:before {
                top: 0;

                @if $flip {
                    transform: skewY($angle * -1);
                    transform-origin: 0 0;
                }
                @else {
                    transform: skewY($angle);
                    transform-origin: 100% 0;
                }
            }

            &:after {
                bottom: 0;

                @if $flip {
                    transform: skewY($angle);
                    transform-origin: 0 0;
                }
                @else {
                    transform: skewY($angle * -1);
                    transform-origin: 100%;
                }
            }
        }
    }
}

@mixin text-colors($map, $p-el: null) {
    @each $theme, $color in $map {
        @include m($theme) {
            color: $color;
        }
    }
}
