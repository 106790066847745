@import "../utilities/variables.scss";
@import "../utilities/mixins.scss";

/****** Modal ******/

.cf-modal {
    z-index: 9999999;

    &-window {
        background: transparentize($black, .2);
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: -1;
        opacity:0;
        height:0;
    }

    input[type=checkbox] {
        display: none;
    }

    input[type=checkbox]:checked ~ .cf-modal-window {
        opacity: 1;
        z-index: $z-index-xlarge;
        height: 100%;
    }

    label {
        cursor: pointer;
    }

    &-content {
        max-width: 440px;
        margin: 60px auto;
        background: $white;
        padding: $base-padding-large;
        position: relative;

        @include mq(small) {
            margin: $base-padding;
            max-height: 90vh;
            overflow: scroll;

            .close {
                position: fixed;
                top: 2em;
                right: 2em;
                cursor: pointer;
            }
        }
        // Themes
        &.full-width {
            background: transparent;
            max-width: 100%;
            color: $white;
            position: static;
            height: 90vh;
        }

        &.home-val-form {
            max-width: 800px;
            padding: $base-padding-large;
        }
        // No Padding
        &.no-padding {
            padding: 0;
        }
    }
    // Modal States
    &-nondismissable {
        position: fixed;
        overflow: hidden;

        .close {
            display: none !important;
        }
    }
    // Blurred state will need to be added on .site-container instead of body
    &-blurred {
        filter: blur(3px);
        -webkit-filter: blur(3px);
    }
}



#imageGalleryModal:checked ~ .cf-modal-window {
    opacity: 1;
    z-index: $z-index-xlarge;
}

.modal {
    &-header {
        h2 {
            font-family: $font-family--primary;
        }
    }

    .btn--link {
        text-decoration: underline;
        font-family: $font-family--primary;
    }
}
.pac-container {
    z-index: 999999!important;
}