@import "../utilities/variables.scss";
@import "../utilities/mixins.scss";

/****** TAGS ******/

.tags {
    display: inline-block;
    padding: 2px $base-padding-xsmall;
    background: $white;
    border: $base-border;
    border-radius: $br-default;
    color: $black;
    margin-bottom: $base-margin;
    margin-right: $base-margin;
    cursor: pointer;

    .icon-close {
        font-size: $type-small;
        padding-left: $base-padding;
    }
    // Themes
    &.small {
        font-size: $type-small;

        .icon-close {
            font-size: $type-xs;
        }
    }

    &.rounded {
        border-radius: 50px;
        padding: 2px 10px;
    }

    &-checkbox {
        display: inline-block;
        // Themes
        &.no-border {
            label {
                border: 0;
                border-radius: 0;
            }

            input[type=checkbox]:checked + label, input[type=radio]:checked + label {
                transition: border $base-transition;
                border-bottom: 3px solid $color-primary;
            }
        }

        &.border {
            input[type=checkbox]:checked + label, input[type=radio]:checked + label {
                transition: border $base-transition;
                border: 1px solid $color-primary;
            }
        }

        &.solid {
            input[type=checkbox]:checked + label, input[type=radio]:checked + label {
                transition: background $base-transition;
                background: $color-primary;
                color: $white;

                i, span {
                    color: $white;
                }
            }
        }

        &.background {
            text-align: center;
            padding-bottom: 16px;

            label {
                height: 100px;
                margin-bottom: 0;
                position: relative;
                transition: all $base-transition;
            }

            input[type=checkbox]:checked + label {
                &:before {
                    content: '\e90c';
                    color: $white;
                    font-size: $type-h2;
                    font-family: icomoon;
                    position: absolute;
                    padding-top: 15px;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    bottom: 0;
                    width: 100%;
                    background: transparentize($color-primary, .3);
                }
            }
        }
    }
}