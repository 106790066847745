﻿@import "../utilities/variables.scss";
@import "../utilities/mixins.scss";
@import "../base/base.scss";

/****** BUTTONS ******/

%btn {
    @extend %small-uppercase-text;
    padding: $base-padding;
    color: $white;
    border-radius: $br-default;
    transition: background-color $base-transition;
    display: inline-block;
    text-align: center;
    margin-bottom: 5px;
    word-break: normal;
    position:relative;

    // Sizes

    &.small {
        font-size: $type-small;
        padding: ($base-padding-xsmall / 2) $base-padding-xsmall;
    }

    &.large {
        font-size: $type-base;
    }

    &.xlarge {
        font-size: $type-h4;
    }
}

%action-btn {
    background: $white;
    box-shadow: $base-box-shadow;
    padding: $base-padding-small;
    border-radius: $br-default;
    color: $black;
}

%round-btn {
    padding: .9em;
    border-radius: $br-round;
    background: $white;
    box-shadow: $base-box-shadow;
    display: inline-block;
    height: 44px;
    width: 44px;
    text-align: center;
    transition: box-shadow $base-transition;
}

%btn-outline {
    @extend %btn;
    color: $black;
    transition: border .2s ease-in-out;
    background: transparent;
}

button {
    -webkit-appearance: none;
    border: 0;
    cursor: pointer;
}

.btn {
    @include solid-buttons($ui-colors);
}

.btn-outline {
    @include outline-buttons($ui-colors);
}

[class^="btn-outline--"] {
    @extend %btn-outline;
}

[class^="btn--"] {
    @extend %btn;
}

// Favorite buttons

.btn--fav {
    padding:0;
    vertical-align: middle;
    &:after {
        content: '\e913';
        font-family: 'icomoon';
        font-size: $type-h4;
        color: $black;
    }

    &.favd {
        &:after {
            content: '\e9b9';
            font-family: 'icomoon';
            font-size: $type-h4;
            color: $color-red;
        }
    }
}

.btn--text {
    @extend %small-uppercase-text;
    color: $grey;
    background: transparent;

    &:hover {
        color: darken($grey, 10%);
    }
    @include mq(small) {
        background: transparent;
    }
}

// Mobile filter / map buttons

.mobile-action-buttons {
    width: 70%;
    position: fixed;
    bottom: $base-padding-medium;
    left: 50%;
    transform: translateX(-50%);
    @extend %action-btn;
    padding: $base-padding-xsmall $base-padding-small;
    text-align: center;
    z-index: 3;
    span {
        color: $color-blue;
        @extend  %small-uppercase-text;
        i {
            font-size: $type-base !important;
        }
    }
    &.above-quickdetails {
        bottom: 40%;
    }
}

// Prop Details Map / Street View / Photo button

.btn--thumbnailinfo {
    width: 80px;
    height: 75px;
    border: 3px solid $white;
    box-shadow: $base-box-shadow;
    position: relative;
    margin-bottom: 0;
    cursor: pointer;

    span {
        background: transparentize($black, .1);
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        font-size: $type-xs;
    }
}

.close {
    font-size: $type-base;
    position: absolute;
    top: $base-padding;
    right: $base-padding;
    cursor: pointer;
    z-index: $z-index-large;
}

.btn-searchasimove {
    @extend %action-btn;
    &.redo-search {
        background: $color-red;
        color: $white;
    }
}

.btn-savesearch {
    @extend %action-btn;
    background: $color-primary;
    color: $white;
}

// Expand map button

.btn--expandmap {
    @extend %action-btn;
    position: fixed;
    top: 200px;
    left: $grid-half;
    transition: all .5s ease-in-out !important;
    color: $black;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    &.expanded {
        left: $grid-1of3;

        @include mq(small-md) {
            left: $grid-half;
        }
    }

    @include mq(small-md) {
        left: $grid-2of3;
    }

    @include mq(small) {
        display: none;
    }

    i {
        font-size: $type-h4;
        color: $black;
    }
}

// Loading idicator sizing

#viewListingsLoader {
    svg {
        height: 35px !important;
        margin-top: -10px;
    }
}

// FB and Google Signup Buttons

.facebook-signin-wrapper, .google-signin-wrapper {
    @include mq(small) {
        width: 48%;
        display: inline-block;
        padding-left: 1rem;
        padding-right: 1rem;
    }
}  